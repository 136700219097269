<script setup>
import { Document } from '@/components/document'
import { useDocument } from '@/composables'
import { useRoute } from 'vue-router';
const route = useRoute();
const { document, isLoading } = useDocument(route.params.id)
</script>

<template>
    <Document :document="document" :isLoading="isLoading" />
</template>
