import { Model } from 'pinia-orm';
import { Folder } from '@/models/Folder';
import { ModelRun } from '@/models/Run';
import { DateCast } from '@/models/utils'

export class Thread extends Model {
    static entity = 'threads'
    static fields() {
        return {
            id: this.attr(null),
            folder_id: this.attr(null),
            name: this.string('Untitled'),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            settings: this.attr({}),

            // Relationships
            folder: this.belongsTo(Folder, 'folder_id'),
            runs: this.hasMany(ModelRun, 'thread_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    get isLocked() {
        return this.runs?.filter(r => r.status === 'running').length > 0;
    }
}
