<script setup>
import { SearchExamples } from '@/components/searchbar/';
import { onMounted, defineProps, toRefs, ref, inject } from 'vue';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useSearch } from '@/composables';
import Upgrade from '@/components/Upgrade';
import { Focus, Filter } from '@/components/searchbar/';
import FileUpload from '@/components/searchbar/FileUpload';
import { usePosthog } from '@/composables/analytics';
import { useSubscriptionStore } from '@/stores/subscription';
const subscription = useSubscriptionStore();
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const props = defineProps(['ctx', 'id'])
const { ctx, id } = toRefs(props)
const { text, sendMessage, focus, areasOfLaw } = useSearch(ctx.value, id.value)
const open = ref(false)
const isMobile = ref(false)

const sourceFilters = ref(focus.value || ['Alles'])
const areaFilters = ref(areasOfLaw.value || ['Alles'])
const selectedDocs = ref([])

async function send() {
    try {
        await sendMessage(sourceFilters.value, areaFilters.value, selectedDocs.value);
    } catch (e) {
        if (e.message === 'Out of credits') {
            identifyUser()
            posthog.capture('$user_out_of_credits', { thread_id: id?.value })
            open.value = true;
        }
    }
}

async function send_example(example) {
    text.value = example;
    send();
}

function handleEnter(event) {
    if (!event.shiftKey) {
        event.preventDefault();
        send();
    }
}

function checkMobile() {
    isMobile.value = window.innerWidth <= 768; // Adjust this value as needed
}

onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
})
</script>

<template>
    <div class="w-full flex flex-col items-center research-tutorial-ref">
        <div class="w-full rounded-xl bg-white dark:bg-backgroundSecondary border border-border  pt-2 pb-2">


            <Textarea
                class="min-h-[110px] max-h-[200px] w-full bg-white dark:bg-backgroundSecondary text-primary/90 mb-2 outline-none shadow-none rounded-lg px-4 "
                id="searchbar-large-textarea" v-model="text" @keydown.enter="handleEnter"
                placeholder="Stel je vraag aan Zeno...." />

            <div class="flex justify-between items-center  px-2">
                <!-- Left side buttons -->
                <div class="flex items-center space-x-1">

                    <Focus @update:sourceFilters="sourceFilters = $event" :defaultFilters="sourceFilters" />
                    <Filter @update:areaFilters="areaFilters = $event" :defaultFilters="areaFilters" />
                    <FileUpload v-if="ctx === 'home' && subscription.plan !== 'free'"
                        @update:selectedDocs="selectedDocs = $event" />
                </div>

                <!-- Right side counter and send button -->
                <div class="flex items-end">
                    <Button variant="ghost" size="sm" @click="send" class="rounded-lg ">
                        <i class='bx bxs-send text-lg'></i>
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <p v-if="ctx === 'home'" class="text-muted-foreground mx-2 text-xs mb-4 mx-auto w-full mt-4">
        Zeno kan fouten maken. Verifieer daarom altijd belangrijke informatie. Antwoorden van Zeno zijn informatief en
        niet bedoeld als juridisch advies.
    </p>
    <div v-if="ctx === 'home'" class="w-[120%] display-hidden-mobile relative">
        <SearchExamples class="mt-4 w-full absolute left-[-10%]" @send-example="send_example" />
    </div>
    <Upgrade :share="true" v-model="open" :no_button="true" />
</template>

<style scoped>
#searchbar-large-textarea {
    resize: none;
    overflow-y: auto;
}

#searchbar-large-textarea:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 768px) {
    .display-hidden-mobile {
        display: none;
    }
}
</style>
