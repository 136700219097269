<script setup>
import { defineProps } from 'vue'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { PanelLeft } from 'lucide-vue-next'
import { useSidebar } from './utils'

const props = defineProps({
  class: { type: String, default: '' },
})

const { toggleSidebar } = useSidebar()
</script>

<template>
  <Button data-sidebar="trigger" variant="ghost" size="icon" :class="cn('h-7 w-7', props.class)" @click="toggleSidebar">
    <PanelLeft />
    <span class="sr-only">Toggle Sidebar</span>
  </Button>
</template>
