<script setup>
import { ref, computed } from 'vue';
import { Button } from '@/components/ui/button';
import { SelectDocumentDialog } from '@/components/documents';
import { Sparkles, FileText } from 'lucide-vue-next';
import PromptDialog from './PromptDialog.vue';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { useSheet } from '@/composables';
import Cell from './Cell.vue';
import { AgGridVue } from 'ag-grid-vue3';
import { Edit } from 'lucide-vue-next';
import { Input } from '@/components/ui/input';
const {
  sheet,
  isLoading,
  addPrompt,
  updatePrompt,
  removePrompt,
  addDocument,
  removeDocument,
  startRun,
  updateSheet
} = useSheet();

const isPromptDialogOpen = ref(false);
const isDocumentDialogOpen = ref(false);
const confirmSelection = (selectedItems) => {
  for (const item of selectedItems) {
    if (!sheet.value.documents.find(d => d.id === item.id)) {
      addDocument(sheet.value.documents.length, item.id);
    }
  }
  const removedDocuments = sheet.value.documents.filter(d => !selectedItems.find(item => item.id === d.id));
  for (const document of removedDocuments) {
    removeDocument(document.id);
  }
};


const getRowId = (params) => {
  return params.data.prompt.id;
};
const defaultColDef = {
  editable: false,
  flex: 1,
}
const colDefs = computed(() => {
  let cols = [
    {
      headerName: 'Prompt',
      valueGetter: params => params.data.prompt.content,
      valueSetter: (params) => {
        params.data.prompt.content = params.newValue
        updatePrompt(params.data.prompt)
      },
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        minLength: 1,
        maxLength: 1000
      },
      type: 'leftAligned',
      editable: true,
      flex: 1,
      pinned: 'left',
      lockPinned: true,
      wrapText: true,
      autoHeight: true,
      headerComponentParams: {
        template:
          `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label flex items-center justify-between gap-2" role="presentation">
                <div class="flex items-center gap-2">
                  <component :is="this.params.headerIcon" class="w-4 h-4 flex-shrink-0 " />
                  <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                </div>
                <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order text-secondary"></span>
                <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon text-secondary"></span>
                <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon text-secondary"></span>
                <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon text-secondary"></span>
                <span data-ref="eFilter" class="ag-header-icon ag-filter-icon text-secondary"></span>
              </div>
            </div>`,
        headerIcon: Sparkles
      }
    },
  ]
  for (const document of sheet.value.documents) {
    cols.push({
      headerName: document.title,
      field: document.id,
      cellRenderer: Cell,
      wrapText: true,
      autoHeight: true,
      headerComponentParams: {
        template:
          `<div class="ag-cell-label-container" role="presentation">
              <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div data-ref="eLabel" class="ag-header-cell-label flex items-center justify-between gap-2" role="presentation">
                <div class="flex items-center gap-2">
                  <component :is="this.params.headerIcon" class="w-4 h-4 flex-shrink-0" />
                  <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                </div>
                <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                <span data-ref="eFilter" class="ag-header-icon ag-filter-icon text-secondary"></span>
              </div>
            </div>`,
        headerIcon: FileText
      }
    })
  }
  return cols
});
const rowData = computed(() => {
  console.log(sheet.value.documents)
  return sheet.value.prompts.map(prompt => {
    return { prompt: prompt, ...Object.fromEntries(sheet.value.documents.map(document => [document.id, sheet.value.cell(prompt, document)])) }
  })
});

const currentEditingSheet = ref(false);

const updateSheetName = async () => {
  await updateSheet(sheet.value);
  currentEditingSheet.value = false;
};
</script>

<template>
  <div v-if="!isLoading" class="w-full p-0 bg-backgroundSecondary h-[calc(100vh-55px)]  flex flex-col ">
    <div class="flex flex-row h-16 border-b border-border bg-background px-4 items-center justify-center mx-auto w-full">
      
      <div class="flex items-center gap-2">
        <div v-if="!currentEditingSheet" class="flex items-center gap-2">
          <h1 class="text-lg font-bold text-primary truncate">{{ sheet?.name }}</h1>
          <Button size="sm" variant="ghost" class="shrink-0" @click="currentEditingSheet = true">
            <Edit class="w-4 h-4" />
          </Button>
        </div>
        <div v-else class="flex items-center gap-2">
          <Input
            v-model="sheet.name"
            class="text-lg font-bold w-auto"
            @keyup.enter="updateSheetName"
            @keyup.esc="currentEditingSheet = false"
            ref="nameInput"
            @blur="updateSheetName"
          />
        </div>
      </div>
    </div>
    <div class="h-full overflow-hidden bg-background flex flex-col p-4">
      
      <div
        class="pb-4 bg-background flex items-center flex-row justify-between space-x-2">
        <div class="flex flex-row space-x-2">
          <Button size="sm" variant="outline" class=" flex flex-row space-x-2 items-center"
            @click="isPromptDialogOpen = true">
            <Sparkles class="w-4 h-4 flex-shrink-0" />
            <span>Beheer prompts</span>
          </Button>

          <Button size="sm" variant="outline" class=" flex flex-row space-x-2 items-center"
            @click="isDocumentDialogOpen = true">
            <FileText class="w-4 h-4 flex-shrink-0" />
            <span>Beheer documenten</span>
          </Button>
        </div>
        <Button @click="startRun" v-if="sheet.prompts?.length > 0" size="sm" variant="gradient"
          class="flex flex-row space-x-2 items-center bg-muted">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-wand-sparkles">
            <path
              d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72" />
            <path d="m14 7 3 3" />
            <path d="M5 6v4" />
            <path d="M19 14v4" />
            <path d="M10 2v2" />
            <path d="M7 8H3" />
            <path d="M21 16h-4" />
            <path d="M11 3H9" />
          </svg>
          <span>Start</span>
        </Button>
      </div>
      <AgGridVue :columnDefs="colDefs" :rowData="rowData" :getRowId="getRowId" :defaultColDef="defaultColDef"
        class="w-full h-full ag-theme-quartz text-left bg-background shadow-sm" :tooltipShowDelay="0.5" />
      <SelectDocumentDialog :isOpen="isDocumentDialogOpen" :callback="confirmSelection"
        :initialSelectedItems="sheet.documents" />
      <PromptDialog :prompts="sheet.prompts" v-model:open="isPromptDialogOpen" @new="addPrompt" @update="updatePrompt"
        @delete="removePrompt" />
    </div>
  </div>
  <div v-else class="flex items-center bg-background justify-center min-h-screen">
    <i class='bx bxs-circle bx-burst bx-lg text-primary'></i>
  </div>
</template>

<style>
/* Grid theme customization */
.ag-theme-quartz {
  --ag-background-color: hsl(var(--background));
  --ag-header-background-color: hsl(var(--background));
  --ag-odd-row-background-color: transparent;
  --ag-row-hover-color: hsl(var(--background-secondary));
  --ag-selected-row-background-color: hsl(var(--muted));
  
  /* Border colors */
  --ag-border-color: hsl(var(--border));
  --ag-row-border-color: hsl(var(--border));
  
  /* Font settings */
  --ag-font-family: var(--font-sans);
  --ag-font-size: 14px;
  
  /* Cell padding */
  --ag-cell-horizontal-padding: 1rem;
  --ag-cell-vertical-padding: 0.5rem;
  
  /* Header styling */
  --ag-header-column-separator-display: none;
  --ag-header-foreground-color: hsl(var(--primary));
}

/* Optional: Style the cell content */
.ag-cell {
  color: hsl(var(--primary));
}

/* Optional: Style the header cells */
.ag-header-cell {
  font-weight: 600;
}

/* Optional: Style the row selection */
.ag-row-selected {
  background-color: hsl(var(--accent)) !important;
}

/* Optional: Style the cell focus */
.ag-cell-focus {
  border-color: hsl(var(--primary)) !important;
}
</style>
