<script setup>
import { watch, onMounted, defineProps, toRefs, ref, inject, computed } from 'vue';
import { Button } from '@/components/ui/button';
import { useSearch } from '@/composables';
import Upgrade from '@/components/Upgrade';
import { usePosthog } from '@/composables/analytics';
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const open = ref(false)
const props = defineProps({
  ctx: String,
  id: String,
  placeholder: {
    type: String,
    default: 'Stel een vervolgvraag...'
  }
});
const { ctx, id } = toRefs(props);
const { text, isLocked, isDisabled, sendMessage } = useSearch(ctx.value, id.value)

const displayPlaceholder = computed(() => {
  return isLocked.value ? 'Zeno is aan het typen...' : props.placeholder
})

function format() {
    if (text.value.endsWith(' ') && text.value.length > 1) {
        return;
    }
    text.value = text.value.replace(/\n/g, '');
    text.value = text.value.trim();
}

function resize() {
    const element = document.getElementById('searchbar-small-textarea');
    if (!element) return;
    element.style.height = '25px';
    if (text.value != '') element.style.height = element.scrollHeight + 'px';
}

watch(text, () => {
    format();
    resize();
});

onMounted(async () => {
    resize();
    let element = null;
    while (!element) {
        element = document.getElementById('searchbar-small-textarea');
        await new Promise((resolve) => setTimeout(resolve, 300));
    }
    element?.focus();
});

async function send() {
    if (isLocked.value) return;
    try {
        await sendMessage()
    } catch (e) {
        if (e.message === 'Out of credits') {
            identifyUser()
            posthog.capture('$user_out_of_credits', { thread_id: id?.value })
            open.value = true;
        }
    }
}

function handleEnter(e) {
    if (!e.shiftKey) {
        e.preventDefault();
        send();
    }

}

</script>

<template>
    <div class="bg-white dark:bg-background border border-border flex items-center justify-center rounded-lg p-1 shadow-sm">
        <div class="py-2 w-full flex flex-row items-center dark:bg-background bg-white rounded-lg z-50"
            style="width: 101%;">
            <div class="bg-transparent h-full flex-grow ">
                <textarea
                    v-model="text"
                    wrap="soft" 
                    class="text-md w-full overflow-y-scroll max-h-[150px] px-4 dark:bg-background outline-none caret-secondary"
                    :class="{ 'text-muted-foreground placeholder-muted-foreground/40': isDisabled, 'text-primary/80': !isDisabled }"
                    id="searchbar-small-textarea" type="text" 
                    :placeholder="displayPlaceholder"
                    :disabled="isLocked || isDisabled"
                    @input="format"
                    @keydown.enter="handleEnter"
                />
            </div>
            <div v-if="isDisabled" class="mx-2 flex items-end self-end">
                <Button variant="outline" @click="send" class="rounded-lg bg-muted px-4">
                    <i class='bx bxs-send text-lg'></i>
                </Button>
            </div>
            <div v-else class="mx-2 flex items-end self-end">
                <Button variant="outline" v-if="!isLocked" @click="send" class="rounded-lg bg-muted px-4">
                    <i class='bx bxs-send text-lg'></i>
                </Button>
                <Button variant="outline" class="rounded-lg bg-muted px-4" v-else>
                    <i class='bx bx-loader-alt bx-spin text-lg'></i>
                </Button>
            </div>
        </div>
    </div>
    <Upgrade :share="true" v-model="open" :no_button="true"/>
</template>

<style scoped>
#searchbar-small-textarea {
    resize: none;
    vertical-align: middle;
    height: auto;
}
</style>