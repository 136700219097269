<script setup>
import { defineProps, defineEmits, ref } from 'vue';

const isHovered = ref(false);

defineProps({
  header: {
    type: String,
    default: 'Nog geen onderzoek gestart'
  },
  paragraph: {
    type: String,
    default: 'Je hebt nog geen onderzoek gestart. Start er nu een en krijg snel antwoord.'
  },
  routerPush: {
    type: String,
    default: '/'
  },
  icon: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['new']);

const handleMouseEnter = () => {
  isHovered.value = true;
};

const handleMouseLeave = () => {
  isHovered.value = false;
};
</script>

<template>
  <div @click="emit('new')" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"
    class="flex h-full shrink-0 items-center justify-center rounded-md border border-dashed hover:border-border hover:bg-backgroundSecondary cursor-pointer">
    <div class="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">

      <component :is="icon" class="w-10 h-10 text-primary" />

      <h3 class="mt-4 text-lg font-semibold">
        {{ header }}
      </h3>
      <p class="mb-4 mt-2 text-sm text-muted-foreground">
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinning-icon {
  animation: spin 2s cubic-bezier(0.8, 0.2, 0.2, 0.8) infinite;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
