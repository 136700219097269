<script setup>
import { Separator } from '@/components/ui/separator'
import { ScrollArea } from '@/components/ui/scroll-area'
import { defineProps, toRefs, computed } from 'vue'
import { getSourceLink, getSourceContent, getSourceTitle, getSourceType, getSourceSearchId } from '@/lib/sources'
import { FollowUpTrigger } from '@/components/threads/runs'
import { Thread } from '@/models'
import { ExternalLink, PencilLine, Search } from 'lucide-vue-next'
import { useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api'
const props = defineProps(['src', 'closeDialog']);
const { src, closeDialog } = toRefs(props);
const route = useRoute();
const threadRepo = useAxiosRepo(Thread).setAxios(api);
const thread = computed(() => { return threadRepo.withAll().find(route.params.id) });

function openInNewTab(url) {
    const win = window.open(url, '_blank')
    win.focus()
}

function generateSummaryQuery(src) {
    if (getSourceType(src) === 'case') {
        return `Geef een uitgebreide samenvatting van: ${getSourceSearchId(src)}`
    }
    return `Geef een uitgebreide uitleg van: ${getSourceTitle(src)}. Maak een helder overzicht en citeer de exacte tekst waar nodig.`
}

function searchSimilarSourcesQuery(src) {
    if (getSourceType(src) === 'case') {
        return `Zoek naar uitspraken die vergelijkbaar zijn met: ${getSourceSearchId(src)}. Zet de uitspraken uiteen en geef een helder overzicht van de overeenkomsten.`
    }
}

</script>

<template>
    <div class="flex flex-col space-y-2">
        <div @click="openInNewTab(getSourceLink(src))"
            class="inline-flex flex-row items-center space-x-1 pb-2 cursor-pointer group">
            <h4 class="text-lg font-bold underline group-hover:text-secondary">
                {{ getSourceTitle(src) }}
            </h4>
            <div>
                <ExternalLink class="w-4 h-4 group-hover:text-secondary" />
            </div>
        </div>
        <Separator class="my-2" />
        <ScrollArea class="h-[200px]">
            <p class="text-sm text-muted-foreground">{{ getSourceContent(src) }}</p>
        </ScrollArea>
        <Separator class="my-2" />
        <div class="flex flex-row space-x-1 pt-2 w-full">
            <FollowUpTrigger ctx="thread" :id="thread.id" :icon="PencilLine"
                :buttonText="getSourceType(src) === 'case' ? 'Samenvatting' : 'Uitleg van wetgeving'"
                :query="generateSummaryQuery(src)" v-model:open="open" :closeDialog="closeDialog"
                :followUpType="getSourceType(src) === 'case' ? 'case_summary' : 'consolidation_summary'" />
            <div class="hidden">
                <FollowUpTrigger v-if="getSourceType(src) === 'case'" ctx="thread" :id="thread.id" :icon="Search"
                    buttonText="Zoek vergelijkbare uitspraken" :query="searchSimilarSourcesQuery(src)"
                    v-model:open="open" :closeDialog="closeDialog" followUpType="case_similarity_search" />
            </div>
        </div>
    </div>
</template>