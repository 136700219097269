<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { getSourceTitle, getSourceType, getSourceId, getSourceSearchId, getSourceLink } from '@/lib/sources'
import { FollowUpTrigger } from '@/components/threads/runs'
import { getSourceIcon } from '@/lib/sources'
import { Badge } from '@/components/ui/badge'
//import { Button } from '@/components/ui/button'
import { Thread } from '@/models'
import { useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api'
import { PencilLine, Search, FileText } from 'lucide-vue-next'
import { defineProps, toRefs, computed } from 'vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { defineModel } from 'vue'

const route = useRoute();
const threadRepo = useAxiosRepo(Thread).setAxios(api);
const thread = computed(() => { return threadRepo.withAll().find(route.params.id) });

const props = defineProps(['i', 'src', 'card'])
const { i, src, card } = toRefs(props)

function generateSummaryQuery(src) {
    if (getSourceType(src) === 'case') {
        return `Geef een uitgebreide samenvatting van de uitspraak ${getSourceId(src)}. Maak een helder overzicht en citeer de exacte tekst waar nodig.`
    }
    return `Geef een uitgebreide uitleg van: ${getSourceTitle(src)}. Maak een helder overzicht en citeer de exacte tekst waar nodig.`
}

function searchSimilarSourcesQuery(src) {
    if (getSourceType(src) === 'case') {
        return `Zoek naar uitspraken die vergelijkbaar zijn met: ${getSourceSearchId(src)}. Zet de uitspraken uiteen en geef een helder overzicht van de overeenkomsten.`
    }
}

const open = defineModel({ default: false })

const closeDialog = () => {
    open.value = false
}

function openInNewTab(url) {
    const win = window.open(url, '_blank')
    win.focus()
}
</script>

<template>

    <Badge v-if="card" variant="outline" class="cursor-pointer bg-backgroundSecondary mr-1">
        <img :src="getSourceIcon(src)" class="h-3 w-3 mr-1" />
        <p> {{ i + 1 }}</p>
    </Badge>
    <Popover v-else :open="open" @update:open="open = !open" asChild>
        <PopoverTrigger asChild>

            <Badge variant="outline" class="cursor-pointer bg-backgroundSecondary hover:bg-accent mr-1">
                <img v-if="src.metadata.document_type !== 'documents'" :src="getSourceIcon(src)" class="h-3 w-3 mr-1" />
                <FileText v-else class="h-3 w-3 mr-1" stroke-width="1.7" stroke-linecap="round"
                    stroke-linejoin="round" />
                <p> {{ i + 1 }}</p>
            </Badge>
        </PopoverTrigger>

        <PopoverContent class="w-80">

            <div class="flex space-y-2 justify-between items-center flex-col w-full">
                <div class="flex flex-row justify-start items-center">
                    <img v-if="src.metadata.document_type !== 'documents'" :src="getSourceIcon(src)"
                        class="h-4 w-4 mr-2" />
                    <FileText v-else class="h-5 w-5 mr-1 justify-start items-start" stroke-width="1.7"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <h4 @click="openInNewTab(getSourceLink(src))" v-if="src.metadata.document_type !== 'documents'"
                        class="text-sm font-semibold cursor-pointer hover:underline">
                        {{ getSourceId(src) }}
                    </h4>
                </div>

                <ScrollArea class="h-[200px] pb-4 px-4" :class="{ 'pb-0': getSourceType(src) === 'case' }">
                    {{ src.page_content }}
                </ScrollArea>

                <Separator v-if="thread && getSourceType(src) !== 'consolidation'" class="" />
                <div v-if="thread && getSourceType(src) !== 'consolidation'"
                    class="flex flex-col space-y-1 pt-2 w-full">
                    <FollowUpTrigger ctx="thread" :id="thread.id" :icon="PencilLine"
                        :buttonText="getSourceType(src) === 'case' ? 'Samenvatting' : 'Uitleg van wetgeving'"
                        :query="generateSummaryQuery(src)" :closeDialog="closeDialog"
                        :followUpType="getSourceType(src) === 'case' ? 'case_summary' : 'consolidation_summary'" />
                    <div class="hidden">
                        <FollowUpTrigger v-if="getSourceType(src) === 'case'" ctx="thread" :id="thread.id"
                            :icon="Search" buttonText="Zoek vergelijkbare uitspraken"
                            :query="searchSimilarSourcesQuery(src)" v-model:open="open" :closeDialog="closeDialog"
                            followUpType="case_similarity_search" />
                    </div>
                </div>
            </div>
        </PopoverContent>
    </Popover>

</template>