import { Model } from 'pinia-orm'
import { User } from '@/models/User'
import { DateCast } from '@/models/utils'

export class Organization extends Model {
    static entity = 'organizations'

    static fields() {
        return {
            id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            name: this.string(''),
            email: this.string(''),
            tax_id: this.string(null),

            // Relationships
            users: this.belongsToMany(User, Membership, 'organization_id', 'user_id'),
            memberships: this.hasMany(Membership, 'organization_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Membership extends Model {
    static entity = 'memberships'

    static fields() {
        return {
            id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            role: this.string(''),
            settings: this.attr(null),
            subscription: this.attr(null),

            // Relationships
            user: this.belongsTo(User, 'user_id'),
            organization: this.belongsTo(Organization, 'organization_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
