import { Model } from 'pinia-orm'
import { Document } from '@/models/Document'
import { Review } from '@/models/Review'
import { Thread } from '@/models/Thread'
import { DateCast } from '@/models/utils'


export class Folder extends Model {
    static entity = 'folders'

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            parent_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            documents: this.hasMany(Document, 'folder_id'),
            reviews: this.hasMany(Review, 'folder_id'),
            threads: this.hasMany(Thread, 'folder_id'),
            parent: this.belongsTo(Folder, 'parent_id'),
            children: this.hasMany(Folder, 'parent_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
