<script setup>
import { PlusIcon, UploadIcon } from 'lucide-vue-next';
import { defineProps } from 'vue';

const props = defineProps({
  icon: {
    type: Function,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  onClick: {
    type: Function,
    required: true
  },
  upload: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  badgeCount: {
    type: Number,
    default: 0
  }
});

const handleClick = (event) => {
  if (!props.disabled) {
    props.onClick(event);
  }
};
</script>

<template>
  <div @click="handleClick" class="flex flex-col w-full h-24 max-w-48 min-w-48 border border-border rounded-lg 
           transition-colors relative p-3" :class="{
            'opacity-50 cursor-not-allowed': disabled,
            'cursor-pointer hover:bg-accent': !disabled
          }">
    <div class="flex justify-between items-start mb-2">
      <div class="border border-border rounded-md p-1.5 text-primary-foreground" 
           :class="{ 
             'bg-backgroundSecondary': label === 'Verwijderen',
             'bg-primary': label !== 'Verwijderen'
           }">
        <component :is="icon" :class="{ 'text-destructive/70': label === 'Verwijderen', 'text-primary-foreground': label !== 'Verwijderen' }" class="h-5 w-5" />
      </div>
      <div class="relative">
        <PlusIcon v-if="!upload && badgeCount === 0" class="h-4 w-4 text-primary" />
        <UploadIcon v-else-if="upload" class="h-4 w-4 text-primary" />
        <span v-else-if="badgeCount > 0"
          class="bg-secondary/20 text-secondary rounded-full w-5 h-5 flex items-center justify-center text-xs font-semibold z-10 border-secondary border">
          {{ badgeCount }}
        </span>
      </div>
    </div>
    <p class="text-sm font-medium text-left mt-auto">{{ label }}</p>
  </div>
</template>
