import { defineStore } from 'pinia';
import { useAuth } from '@/auth';
import { ref } from 'vue';
import { io } from 'socket.io-client'
import { ModelRun, Comment, Prompt, SheetPrompt, SheetDocument, Document, Review, Thread, Sheet } from '@/models'
import { useRepo } from 'pinia-orm';
import { useSubscriptionStore } from '@/stores/subscription';

export const useSocket = defineStore('socket', () => {
    const { getAccessToken } = useAuth()
    const sockets = ref({})
    const runRepo = useRepo(ModelRun)
    const commentRepo = useRepo(Comment)
    const promptRepo = useRepo(Prompt)
    const sheetPromptRepo = useRepo(SheetPrompt)
    const sheetDocumentRepo = useRepo(SheetDocument)
    const documentRepo = useRepo(Document)
    const reviewRepo = useRepo(Review)
    const threadRepo = useRepo(Thread)
    const sheetRepo = useRepo(Sheet)
    const subscription = useSubscriptionStore()

    async function connect(namespace) {
        if (sockets.value[namespace] && sockets.value[namespace].connected) return
        sockets.value[namespace] = io(
            `${process.env.VUE_APP_API_URL}/${namespace}`, {
                path: '/ws',
                auth: {
                    token: await getAccessToken()
                },
                withCredentials: true,
                transports: ['websocket']
            }
        )
        sockets.value[namespace].on('review', (data) => reviewRepo.save(data))
        sockets.value[namespace].on('sheet', (data) => sheetRepo.save(data))
        sockets.value[namespace].on('thread', (data) => threadRepo.save(data))
        sockets.value[namespace].on('comment', (data) => commentRepo.save(data))
        sockets.value[namespace].on('prompt', (data) => promptRepo.save(data))
        sockets.value[namespace].on('sheet-prompt', (data) => sheetPromptRepo.save(data))
        sockets.value[namespace].on('document', (data) => documentRepo.save(data))
        sockets.value[namespace].on('sheet-document', (data) => sheetDocumentRepo.save(data))
        sockets.value[namespace].on('run', async (data) => {
            if (data.data) {
                runRepo.save(data.data)
            }
            if (data.error) {
                data.data.status = 'error'
                runRepo.save(data.data)
                throw new Error(data.error)
            }
            if (data.data?.status !== 'running') {
                await subscription.fetchUsage();
            }
        })
        sockets.value[namespace].on('disconnect', () => sockets.value[namespace] = null)
    }

    async function emit(namespace, event, data) {
        if (!sockets.value[namespace]) await connect(namespace)
        sockets.value[namespace].emit(event, data)
    }

    return { emit, connect }
});