import { ref } from 'vue';
import { useShepherd } from 'vue-shepherd';
import { useOnboardingStore } from '@/stores/onboarding';
import { useSubscriptionStore } from '@/stores/subscription';

const tourState = ref({
    currentTour: null,
    isActive: false
  });

export function useTour() {
    const tour = useShepherd({
        useModalOverlay: true,
        defaultStepOptions: {
        cancelIcon: {
            enabled: true
        },
        scrollTo: { behaviour: 'smooth', block: 'center'}

        }
    });

    const endTour = () => {
        tour.complete();
        tour.steps = [];
        tourState.value.isActive = false;
        tourState.value.currentTour = null;
        const onboardingStore = useOnboardingStore();
        onboardingStore.completeOnboarding();
      };

    const startTour = (tourName) => {
        if (tours[tourName]) {
          endTour();  
          const subscription = useSubscriptionStore();
          const steps = tours[tourName].filter(step => {
            if (step.title === 'Huidig abonnement') {
              return subscription.plan === 'pro';
            }
            return true;
          });
          tour.addSteps(steps);
          tourState.value.currentTour = tourName;
          tourState.value.isActive = true;
          tour.start();
        }
      };
    
      return {
        startTour,
        endTour,
        tour,
        tourState,
        tours
      };
}

export const tours = {
  home: [
    {
      title: '<div class="flex flex-row items-center justify-center space-x-2"><div class="flex items-center p-2 bg-background border border-border rounded-xl mr-1"><div class="inline-block w-10 h-10 bg-contain bg-no-repeat bg-center" style="background-image: var(--symbol);"></div></div><h4 class="text-lg font-bold">Welkom bij Zeno!</h4></div>',
      text: '<p class="text-md py-2 text-primary/75">Zeno is ontworpen om juridische professionals van A tot Z te ondersteunen met AI-tools toegespitst op het Nederlands recht.</p>',
      attachTo: {
        element: () => document.querySelector('.body'),
        on: 'center'
      },
      buttons: [
        {
          text: 'Sla over',
          action() { return this.complete(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Start tour',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
      title: 'Research met Zeno',
      text: '<p class="text-md text-primary/75">Een onderzoek start je door een casus te beschrijven in de tekstbalk. Voor de beste resultaten is het belangrijk om de casus met alle relevante context te beschrijven en af te sluiten met een vraag. Op deze manier zijn de bevindingen van Zeno het meest nauwkeurig. <a href="https://www.zeno.law/faq" target="_blank" class="text-secondary cursor-pointer underline">Klik hier</a> om meer te lezen over het optimaal beschrijven van een casus.</p>',
      attachTo: {
        element: () => document.querySelector('.research-tutorial-ref'),
        on: 'top'
      },
      buttons: [
        {
          text: 'Terug',
          action() { return this.back(); },
          classes: 'custom-tour-secondary-button'
        },
        {
          text: 'Volgende',
          action() { return this.next(); },
          classes: 'custom-tour-main-button'
        }
      ]
    },
    {
        title: 'Research geschiedenis',
        text: '<p class="text-md text-primary/75">Hier kun je al je onderzoeken vinden. Handig als je bijvoorbeeld op een later moment je onderzoek wilt hervatten of wilt teruglezen.</p>',
        attachTo: {
          element: () => document.querySelector('.research-sidebar-tutorial-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ]
      },
      {
        title: 'Vault',
        text: '<p class="text-md text-primary/75">Met vault kun je documenten opslaan voor later gebruik. De documenten blijven veilig in onze vault. Lees meer over onze veilige opslag <a href="https://www.zeno.law/security" target="_blank" class="text-secondary cursor-pointer underline">hier</a>.</p>',
        attachTo: {
          element: () => document.querySelector('.vault-sidebar-tutorial-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ],
        when: {
          show: () => {
            const subscription = useSubscriptionStore();
            return subscription.plan === 'pro';
          }
        }
      },
      {
        title: 'Sheets',
        text: '<p class="text-md text-primary/75">Met sheets kun je meerdere documenten tegelijk uploaden en laten analyseren aan de hand van een vragenlijst. Het resultaat is een uitgebreide analyse van de documenten gepresenteerd in een tabel.</p>',
        attachTo: {
          element: () => document.querySelector('.sheets-sidebar-tutorial-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ],
        when: {
          show: () => {
            const subscription = useSubscriptionStore();
            return subscription.plan === 'pro';
          }
        }
      },
      {
        title: 'Document review',
        text: '<p class="text-md text-primary/75">Met review kun je documenten uploaden en Zeno laten analyseren. Het resultaat is een uitgebreide analyse van het document met de bijbehorende citaties naar relevante juridische bronnen.</p>',
        attachTo: {
          element: () => document.querySelector('.review-sidebar-tutorial-ref'),
          on: 'right'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Volgende',
            action() { return this.next(); },
            classes: 'custom-tour-main-button'
          }
        ],
        when: {
          show: () => {
            const subscription = useSubscriptionStore();
            return subscription.plan === 'pro';
          }
        }
      },
    {
        title: 'Ben je er klaar voor?',
        text: '<p class="text-md text-primary/75">Ga aan de slag en start jouw eerste onderzoek met Zeno.</p>',
        attachTo: {
          element: () => document.querySelector('.body'),
          on: 'center'
        },
        buttons: [
          {
            text: 'Terug',
            action() { return this.back(); },
            classes: 'custom-tour-secondary-button'
          },
          {
            text: 'Aan de slag',
            action() { return this.complete(); },
            classes: 'custom-tour-main-button'
          }
        ]
    }
  ]
};
