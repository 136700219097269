<script setup>
import { Button } from '@/components/ui/button';
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Mail, MessageSquare, Phone } from 'lucide-vue-next';
function open(link) {
    window.open(link, '_blank')
}
</script>

<template>
    <AlertDialog>
        <AlertDialogTrigger class="w-full">
            <Button class="w-full gradient-border bg-muted justify-start px-4" size="sm" variant="ghost">
                <MessageSquare class="w-3.5 h-3.5 mr-2" />
                <p class="text-xs tracking-tight">
                    Support
                </p>
            </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>

            <div class="absolute top-4 right-4">
                <AlertDialogCancel class="p-1 hover:bg-background hover:text-secondary border-none rounded-full">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                            fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                </AlertDialogCancel>
            </div>
            <AlertDialogHeader>
                <AlertDialogTitle>Wij staan voor je klaar!</AlertDialogTitle>
                <AlertDialogDescription class="space-y-4">
                    <p class="text-sm">
                        Wij streven ernaar om binnen 12 uur op je bericht te reageren. Onze toegewijde
                        supportmedewerkers staan klaar om je te helpen met al je vragen en verzoeken. Kies hieronder je
                        voorkeursmanier om contact met ons op te nemen:
                    </p>
                    <div class="grid grid-cols-3 gap-2 w-full">
                        <Button class="col-span-1" variant="outline" @click="open('tel:+31648963090')">
                            <Phone class="w-4 h-4 mr-2" />
                            <p>
                                Telefoon
                            </p>
                        </Button>
                        <Button class="col-span-1" variant="outline" @click="open('https://wa.me/31683352096')">
                            <MessageSquare class="w-4 h-4 mr-2" />
                            <p>
                                WhatsApp
                            </p>
                        </Button>
                        <Button class="col-span-1" variant="outline" @click="open('mailto:support@zeno.law')">
                            <Mail class="w-4 h-4 mr-2" />
                            <p>
                                Mail
                            </p>
                        </Button>
                    </div>
                </AlertDialogDescription>
            </AlertDialogHeader>
        </AlertDialogContent>
    </AlertDialog>

</template>