import { cva } from "class-variance-authority";

export { default as Badge } from "./Badge.vue";

export const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-background border border-destructive/40 text-destructive/80 hover:bg-destructive/30",
        outline: "text-foreground",
        accent: "bg-accent text-accent-foreground hover:bg-accent/80",
        pro: "bg-muted text-gradient gradient-border",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
