import { Model } from 'pinia-orm'
import { Organization, Membership } from '@/models/Organization'
import { useScope } from '@/composables'
import { DateCast } from '@/models/utils'


export class User extends Model {
    static entity = 'users'

    static fields() {
        return {
            id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            first_name: this.string(''),
            last_name: this.string(''),
            email: this.string(''),
            email_verified: this.boolean(false),
            profile_picture_url: this.string(null),
            settings: this.attr(null),
            subscription: this.attr(null),

            // Relationships
            organizations: this.belongsToMany(Organization, Membership, 'user_id', 'organization_id'),
            memberships: this.hasMany(Membership, 'user_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    get role() {
        const { organization } = useScope()
        if (!organization) {
            return null
        }
        return this.memberships?.filter(m => m.organization_id === organization.value.id)[0].role
    }

    set role(role) {
        const { organization } = useScope()
        if (!organization) {
            return
        }
        if (!this.memberships?.filter(m => m.organization_id === organization.value.id)[0]) {
            return
        }
        this.memberships.filter(m => m.organization_id === organization.value.id)[0].role = role.toLowerCase()
    }
}
