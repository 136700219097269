import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';


export const useSidebarStore = defineStore('sidebar', () => {
  const router = useRouter();
  const isSidebarOpen = ref(true);

  function toggleSidebar() {
    isSidebarOpen.value = !isSidebarOpen.value;
  }

  function isLargeScreen() {
    return window.innerWidth >= 1024;
  }

  function pushRouteWithSidebarToggle(routeName) {
    if (!isLargeScreen() && isSidebarOpen.value) {
      toggleSidebar();
    }
    router.push({ name: routeName });
  }
  
  return {
    isSidebarOpen,
    toggleSidebar,
    isLargeScreen,
    pushRouteWithSidebarToggle
  };
});