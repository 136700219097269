<script setup>
import { defineProps, toRefs } from 'vue'
import Run from '@/components/sidepanel/Run.vue'
import { SearchBar } from '@/components/searchbar'
const props = defineProps(['ctx', 'id', 'runs']);
const { ctx, id, runs } = toRefs(props);
</script>

<template>
    <div class="flex flex-col bg-background overflow-hidden h-full w-full pb-2 ">
                        
        <div class="flex flex-row items-center justify-center px-4 py-4 border-b border-borde flex-shrink-0 h-16">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wand-sparkles mr-3"><path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72"/><path d="m14 7 3 3"/><path d="M5 6v4"/><path d="M19 14v4"/><path d="M10 2v2"/><path d="M7 8H3"/><path d="M21 16h-4"/><path d="M11 3H9"/></svg> 
            <h1 class="text-lg font-semibold tracking-tight">Assistent</h1>
        </div>
                

        <div class="flex flex-col flex-grow overflow-hidden bg-background ">
                <div class="flex flex-col h-full">
                    <div class="flex-grow overflow-y-auto px-4 py-2">
                        <Run v-for="run in runs" :key="run.id" :run="run" />
                    </div>
                    <SearchBar class="px-4 py-2 " size="lg" :ctx="ctx" :id="id" />
                </div>
           
        </div>
    </div>
</template>
