<script setup>

const examples_1 = [
  'Wat zijn de criteria van onrechtmatige daad?',
  'Hoe oordelen rechters over de vereisten van ontbinding bij aanneming van werk?',
  'Geef een uitgebreide samenvatting van ECLI:NL:HR:2019:2006, zet belangrijkste overwegingen in een tabel',
 
];

const examples_2 = [
  'Wat zijn de aandachtspunten voor werkgevers bij eenzijdige beëindiging arbeidsovereenkomst?',
  'In welke situaties is er sprake van schijnzelfstandigheid?',
  'Geef een uitleg over de vreemdelingenwet',
];
</script>

<template>
  <div class="example-scroll-container overflow-hidden">
    <div v-for="row in 2" :key="row" class="example-scroll-row">
      <div class="example-scroll-content" :class="row === 1 ? 'scroll-left' : 'scroll-right'">
        <div class="example-scroll-inner">
          <div 
            v-for="example in (row === 1 ? examples_1 : examples_2)" 
            :key="`${row}-${example}`" 
            class="example-item bg-backgroundSecondary p-3 rounded-xl border border-border cursor-pointer mr-4 mb-2 hover:bg-accent "
            @click="$emit('send-example', example)"
          >
            <p class="text-xs whitespace-nowrap">{{ example }}</p>
          </div>
        </div>
        <div class="example-scroll-inner" aria-hidden="true">
          <div 
            v-for="example in (row === 1 ? examples_1 : examples_2)" 
            :key="`${row}-${example}-duplicate`" 
            class="example-item bg-backgroundSecondary p-3 rounded-xl border border-border cursor-pointer mr-4 mb-2 hover:bg-accent "
            @click="$emit('send-example', example)"
          >
            <p class="text-xs whitespace-nowrap">{{ example }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.example-scroll-container {
  width: 100%;
  position: relative;
}

.example-scroll-row {
  overflow: hidden;
  margin: 0.5rem 0;
  mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.example-scroll-content {
  display: flex;
  width: fit-content;
}

.example-scroll-inner {
  display: flex;
  flex-shrink: 0;
}

.example-item {
  flex-shrink: 0;
}

@keyframes scrollLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

@keyframes scrollRight {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0); }
}

.scroll-left {
  animation: scrollLeft 30s linear infinite;
}

.scroll-right {
  animation: scrollRight 30s linear infinite;
}

.example-scroll-content:hover {
  animation-play-state: paused;
}
</style>