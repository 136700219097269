<script setup>
import { defineProps, toRefs } from 'vue'
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
const auth = useAuth();
const { user } = storeToRefs(auth);
const props = defineProps(['query'])
const { query } = toRefs(props)

// Add a new function to get user name
const getUserName = (userId) => {
  if (user.value && user.value.id === userId) {
    return `${user.value.first_name} ${user.value.last_name}`;
  }
  return 'N/A';
};

</script>

<template>
    <div class="flex flex-col justify-start w-full ">
        <!-- User message container with right alignment -->
        <div class="flex flex-col items-end w-full">
            <div class="flex items-end justify-start mb-1">
                <!-- Name -->
                <span class="text-xs">{{ getUserName(user.id) }}</span>
                
            </div>
            <!-- Query text -->
            <div class="bg-accent rounded-lg rounded-br-none  gradient-border shadow-sm text-left px-4 py-2 max-w-[65%]">
                <p class="break-words text-sm">
                    {{ query }}
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .text-2-answer {
        font-family: 'Inter', sans-serif;
        font-size: 1rem!important;
        line-height: 2.5rem;
        font-weight: 400;
    }
    .text-3-answer {
        font-family: 'Inter', sans-serif;
        font-size: 1rem!important;
        line-height: 2.5rem;
        font-weight: 400;
    }
    .text-4-answer {
        font-family: 'Inter', sans-serif;
        font-size: 1rem!important;
        line-height: 2.6rem;
        font-weight: 400;
    }

    .text-4-answer .strong {
        font-family: 'Inter', sans-serif;
        font-size: 1rem!important;
        line-height: 2.5rem;
        font-weight: 400;
    }

    .text-p-answer {
        font-family: 'Inter', sans-serif;
        font-size: 0.98rem!important;
    }
</style>