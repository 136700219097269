<script setup>
import { SidebarMenuButton, SidebarMenuSub, SidebarMenuSubItem, SidebarMenuSubButton, SidebarMenuAction, SidebarInput, SidebarMenuSubAction } from '@/components/ui/sidebar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible';
import { MoreHorizontal, Edit, Trash2, ChevronRight, ChevronDown } from 'lucide-vue-next';
import { defineProps, ref, watch, onBeforeMount, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { ModelRun, DocumentPart, Document, ReviewDocument, SheetDocument, Prompt, SheetPrompt } from '@/models';
import { Badge } from '@/components/ui/badge';
import { api } from '@/plugins/api';

const router = useRouter()
const route = useRoute();
const isOpen = ref(false);
const prefilled = ref([]);

const props = defineProps({
    icon: { type: Function, required: true },
    label: { type: String, required: true },
    itemIcon: { type: Function, default: () => { } },
    routeName: { type: String, default: null },
    itemRouteName: { type: String, default: null },
    model: { type: Function, default: null },
    disabled: { type: Boolean, default: false }
})
const currentEditingItemId = ref(null)

async function updateItemName(item) {
    await repo.api().put(`/${props.itemRouteName}s/${item.id}`, item)
    currentEditingItemId.value = null
}

watch(route, (newVal) => {
    if (newVal.name === props.routeName || newVal.name === props.itemRouteName) {
        isOpen.value = true
    }
})

const repo = useAxiosRepo(props.model).setAxios(api)
const runRepo = useAxiosRepo(ModelRun).setAxios(api)
const documentPartRepo = useAxiosRepo(DocumentPart).setAxios(api)
const documentRepo = useAxiosRepo(Document).setAxios(api)
const reviewDocumentRepo = useAxiosRepo(ReviewDocument).setAxios(api)
const sheetDocumentRepo = useAxiosRepo(SheetDocument).setAxios(api)
const promptRepo = useAxiosRepo(Prompt).setAxios(api)
const sheetPromptRepo = useAxiosRepo(SheetPrompt).setAxios(api)

function startEditing(item) {
    currentEditingItemId.value = item.id
}

onBeforeMount(async () => {
    await repo.api().get(`/${props.itemRouteName}s/`, { params: { limit: 15 } })
})

async function prefill(id) {
    const resource = repo.withAllRecursive().find(id)
    if (!resource.runs.length && !prefilled.value.includes(id)) {
        prefilled.value.push(id)
        const promises = []
        promises.push(runRepo.api().get(`/${props.itemRouteName}s/${id}/runs`))
        if (props.itemRouteName === 'review') {
            promises.push(reviewDocumentRepo.api().get(`/reviews/${id}/review-documents`))
            promises.push(documentRepo.api().get(`/reviews/${id}/documents`))
            promises.push(documentPartRepo.api().get(`/reviews/${id}/document-parts`))
        }
        if (props.itemRouteName === 'sheet') {
            promises.push(sheetDocumentRepo.api().get(`/sheets/${id}/sheet-documents`))
            promises.push(documentRepo.api().get(`/sheets/${id}/documents`))
            promises.push(promptRepo.api().get(`/sheets/${id}/prompts`))
            promises.push(sheetPromptRepo.api().get(`/sheets/${id}/sheet-prompts`))
        }
        await Promise.all(promises)
    }
}

const items = computed(() => {
    return repo.orderBy('updated_at', 'desc').limit(5).all() || []
})

async function deleteItem(item) {
    await repo.api().delete(`/${props.itemRouteName}s/${item.id}`, { delete: item.id })
    router.push({ name: props.routeName })
}
</script>

<template>
    <Collapsible :open="isOpen" @update:open="isOpen = !isOpen" :disabled="disabled">
        <SidebarMenuButton :tooltip="label" @click="router.push({ name: routeName })"
            :is-active="route.name === routeName" :disabled="disabled">
            <component :is="icon" />
            <span>{{ label }}</span>
        </SidebarMenuButton>
        <CollapsibleTrigger as-child>
            <SidebarMenuAction>
                <template v-if="disabled">
                    <Badge variant="outline"
                        class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                        <p class="text-xs text-gradient"> Pro </p>
                    </Badge>
                </template>
                <template v-else>
                    <ChevronRight v-if="!isOpen" />
                    <ChevronDown v-else />
                </template>
            </SidebarMenuAction>
        </CollapsibleTrigger>
        <CollapsibleContent>
            <SidebarMenuSub>
                <SidebarMenuSubItem v-if="items.length === 0">
                    <SidebarMenuSubButton size="sm" as="a">
                        <span>Leeg...</span>
                    </SidebarMenuSubButton>
                </SidebarMenuSubItem>
                <SidebarMenuSubItem v-for="item in items" :key="item.id" @mouseover="prefill(item.id)">
                    <SidebarMenuSubButton size="sm" as="button" class="w-full"
                        @click="router.push({ name: itemRouteName, params: { id: item.id } })"
                        :is-active="route.params.id === item.id && route.name === itemRouteName">
                        <component :is="itemIcon" />
                        <SidebarInput v-if="currentEditingItemId === item.id" v-model="item.name"
                            @keydown.enter="updateItemName(item)" @blur="updateItemName(item)" autofocus @click.stop
                            class="h-4" />
                        <span v-else>{{ item.name }}</span>
                    </SidebarMenuSubButton>
                    <DropdownMenu>
                        <DropdownMenuTrigger as-child>
                            <SidebarMenuSubAction class="-mr-6" show-on-hover>
                                <MoreHorizontal />
                            </SidebarMenuSubAction>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="right" align="start">
                            <DropdownMenuItem @click="startEditing(item)" class="space-x-2">
                                <Edit class="w-3 h-3 " />
                                <p class="text-sm">Bewerken</p>
                            </DropdownMenuItem>
                            <DropdownMenuItem @click="deleteItem(item)" class="space-x-2">
                                <Trash2 class="w-3 h-3" />
                                <p class="text-sm">Verwijderen</p>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </SidebarMenuSubItem>
            </SidebarMenuSub>
        </CollapsibleContent>
    </Collapsible>
</template>