<script setup>
import { Overview } from '@/components/documents';
import { useRoute } from 'vue-router';
import { onMounted, watch } from 'vue';
import { useDocumentStore } from '@/stores/';

//const router = useRouter();
const route = useRoute();
const dms = useDocumentStore();

onMounted(async () => {

    const folderPath = route.params.folderPath;
    if (folderPath) {
        await dms.navigateToPath(Array.isArray(folderPath) ? folderPath.join('/') : folderPath);
    } else {
        await dms.toFolder(dms.currentFolderId);
    }
});


watch(() => route.params.folderPath, async (newPath) => {
    if (newPath) {
        await dms.navigateToPath(Array.isArray(newPath) ? newPath.join('/') : newPath);
    } else {

        await dms.toFolder(dms.currentFolderId);
    }
});
</script>

<template>
    <div class="w-full p-0 bg-backgroundSecondary">
        <div class="rounded-none h-[calc(100vh-55px)] overflow-hidden  bg-background flex flex-col">
            <Overview />
        </div>
    </div>
</template>
