<script setup>
import { useAxiosRepo } from '@pinia-orm/axios'
import { api } from '@/plugins/api';
import { computed, defineProps, ref, defineEmits } from 'vue';
import { EditIcon, TrashIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import EmptyPlaceholder from '@/components/ResourceList/EmptyPlaceholder.vue';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
const props = defineProps(['model', 'icon', 'emptyPlaceholderHeader', 'emptyPlaceholderParagraph', 'emptyIcon']);
const emit = defineEmits(['click', 'update', 'delete', 'new']);
const auth = useAuth();
const { user } = storeToRefs(auth);
const repo = useAxiosRepo(props.model).setAxios(api);
const editingId = ref(null);
const items = computed(() => {
    return repo.orderBy('created_at', 'desc').get();
});

const handleUpdate = (item) => {
    emit('update', item);
    editingId.value = null;
};
</script>

<template>
    <div v-if="items.length > 0" class="space-y-2 rounded-lg h-full">
        <div v-for="item in items" :key="item.id"
            class="flex flex-col  px-4 pt-4 pb-4 rounded-lg border border-border bg-backgroundSecondary shadow-sm hover:bg-muted transition-colors duration-200 relative cursor-pointer group"
            @click="emit('click', item.id)">
            <div class="absolute top-2 right-4 flex items-center space-x-2 z-10" @click.stop>
                <Button @click="editingId = item.id" variant="outline" size="sm"
                    class="text-muted-foreground  hover:text-foreground hover:border hover:border-border">
                    <EditIcon class="h-4 w-4" />
                </Button>
                <Button @click="emit('delete', item.id)" variant="outline" size="sm"
                    class="text-muted-foreground hover:text-foreground hover:border hover:border-border">
                    <TrashIcon class="h-4 w-4" />
                </Button>
            </div>
            <div class="pr-24">
                <div class="flex flex-row items-center justify-start pb-2">
                    <props.icon class="flex-shrink-0" />
                </div>
                <div v-if="editingId === item.id" class="flex-grow mb-2 z-10" @click.stop>
                    <Input v-model="item.name" @keydown.enter="handleUpdate(item)" @blur="handleUpdate(item)"
                        class="text-sm font-medium w-full h-10" autofocus />
                </div>
                <div v-else class="flex-grow mb-2">
                    <p class="text-left text-sm font-medium tracking-tight truncate">
                        {{ item.name }}
                    </p>
                </div>
            </div>
            <div class="flex items-center justify-start space-x-2">
                <p class="text-xs text-muted-foreground">
                    {{ new Date(item.created_at).toLocaleDateString() }}
                </p>
                <p class="text-xs text-muted-foreground">
                    {{ user.first_name }} {{ user.last_name }}
                </p>
            </div>
        </div>
    </div>
    <EmptyPlaceholder v-else class="h-full" :icon="emptyIcon" :header="emptyPlaceholderHeader"
        :paragraph="emptyPlaceholderParagraph" @new="emit('new')" />
</template>
