import { Model } from 'pinia-orm'
import { Document } from '@/models/Document'
import { Prompt } from '@/models/Prompt'
import { ModelRun } from '@/models/Run'
import { DateCast } from '@/models/utils'

export class SheetDocument extends Model {
    static entity = 'sheet_documents'

    static fields() {
        return {
            id: this.attr(null),
            sheet_id: this.attr(null),
            index: this.number(0),
            document_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }
}

export class SheetPrompt extends Model {
    static entity = 'sheet_prompts'

    static fields() {
        return {
            id: this.attr(null),
            sheet_id: this.attr(null),
            index: this.number(0),
            prompt_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }
}

export class Sheet extends Model {
    static entity = 'sheets'

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            folder_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            settings: this.attr({}),

            // Relationships
            documents: this.belongsToMany(Document, SheetDocument, 'sheet_id', 'document_id'),
            prompts: this.belongsToMany(Prompt, SheetPrompt, 'sheet_id', 'prompt_id'),
            runs: this.hasMany(ModelRun, 'sheet_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    cell(prompt, document) {
        return this.runs.find(run => run.prompt_id === prompt.id && run.document_ids.includes(document.id))
    }
}
