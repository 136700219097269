import { computed, onMounted, onUnmounted, watch, ref } from 'vue';
import { Review, Document, DocumentPart, ModelRun, ReviewDocument } from '@/models';
import { useSocket } from '@/stores/socket';
import { useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { useRouter } from 'vue-router';

export function useReview() {
    const router = useRouter();
    const route = useRoute();
    const repo = useAxiosRepo(Review).setAxios(api)
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const documentPartRepo = useAxiosRepo(DocumentPart).setAxios(api)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    const reviewDocumentRepo = useAxiosRepo(ReviewDocument).setAxios(api)
    const socket = useSocket();
    const isLoading = ref(true);
    const review = computed(() => {
        return repo.withAllRecursive().find(route.params.id);
    });

    async function leave() {
        if (!review.value) return;
        await socket.emit('reviews', 'leave_review', { review_id: review.value.id })
    }

    async function enter() {
        isLoading.value = true
        await socket.connect('reviews')
        if (!review.value) {
            router.push({ name: 'home' })
            return;
        }
        const promises = []
        if (!review.value.documents?.length) {
            promises.push(documentRepo.api().get(`/reviews/${route.params.id}/documents`))
            promises.push(reviewDocumentRepo.api().get(`/reviews/${route.params.id}/review-documents`))
            promises.push(documentPartRepo.api().get(`/reviews/${route.params.id}/document-parts`))
        }
        if (!review.value.runs?.length) {
            promises.push(runRepo.api().get(`/reviews/${route.params.id}/runs`))
        }
        await Promise.all(promises)
        await socket.emit('reviews', 'enter_review', { review_id: review.value.id })
        isLoading.value = false
    }

    onMounted(enter)
    watch(route.params.id, async (oldId, newId) => {
        if (oldId === newId) return
        await enter()
    })
    onUnmounted(leave)

    return { review, isLoading }
}
