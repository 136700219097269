import { Model } from 'pinia-orm'
import { Thread } from '@/models/Thread'
import { Review } from '@/models/Review'
import { Sheet } from '@/models/Sheet'
import { Prompt } from '@/models/Prompt'
import { Comment } from '@/models/Document'
import { DateCast } from '@/models/utils'

export class ModelRun extends Model {
    static entity = 'runs'
    static fields() {
        return {
            id: this.attr(null),
            thread_id: this.attr(null),
            review_id: this.attr(null),
            sheet_id: this.attr(null),
            prompt_id: this.attr(null),
            document_ids: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            status: this.string('running'),
            plan: this.attr(null),
            query: this.string(null),
            sources: this.attr(null),
            referenced_sources: this.attr(null),
            answer: this.attr(null),
            suggested_follow_ups: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            thread: this.belongsTo(Thread, "thread_id"),
            review: this.belongsTo(Review, "review_id"),
            sheet: this.belongsTo(Sheet, "sheet_id"),
            prompt: this.belongsTo(Prompt, "prompt_id"),
            comments: this.hasMany(Comment, "run_id"),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
