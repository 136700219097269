<script setup>
import { ref, defineProps, defineModel, computed } from 'vue';
import DynamicDocumentsTable from './areas/DynamicDocumentsTable.vue';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';

const isOpen = defineModel('isOpen', {
    type: Boolean,
    required: false,
    default: false
});

const props = defineProps({
    initialSelectedItems: {
        type: Array,
        required: false,
        default: () => []
    },
    callback: {
        type: Function,
        required: true
    },
    columns: {
        type: Array,
        required: false,
        default: () => ['Document', 'Laatst gebruikt']
    },
    single: {
        type: Boolean,
        required: false,
        default: false
    }
});

const selectedItems = ref(props.initialSelectedItems);
const columnOptions = [
    { label: 'Document', type: 'name' },
    { label: 'Laatst gebruikt', type: 'date', field: 'updated_at' },
];
const selectedColumns = computed(() => {
    return columnOptions.filter(column => props.columns.includes(column.label));
});

const confirmSelection = async () => {
    if (selectedItems.value.length > 0) {
        isOpen.value = false
        await props.callback(selectedItems.value);
    }
};
</script>

<template>
    <Dialog :open="isOpen" @update:open="isOpen = $event">
        <DialogContent class="bg-backgroundSecondary w-[800px] max-w-[90vw]">
            <DialogHeader>
                <DialogTitle v-if="props.single">Selecteer een document</DialogTitle>
                <DialogTitle v-else>Selecteer documenten</DialogTitle>
            </DialogHeader>
            <DialogDescription v-if="props.single">Selecteer het document dat je wilt gebruiken voor je vraag.
            </DialogDescription>
            <DialogDescription v-else>Selecteer de documenten die je wilt gebruiken voor je vraag.</DialogDescription>
            <div class="py-2">
                <DynamicDocumentsTable :columns="selectedColumns" :selectable="true"
                    :initialSelectedItems="selectedItems" @selectionChange="selectedItems = $event"
                    @confirmSelection="confirmSelection" :single="props.single" />
            </div>
        </DialogContent>
    </Dialog>
</template>