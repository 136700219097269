import { Model } from 'pinia-orm'
import { Organization } from '@/models/Organization'
import { User } from '@/models/User'
import { DateCast } from '@/models/utils'


export class Subscription extends Model {
    static entity = 'subscriptions'

    static fields() {
        return {
            id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            start_date: this.attr(null),
            end_date: this.attr(null),
            status: this.string(''),
            plan: this.string(''),
            settings: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),

            // Relationships
            user: this.belongsTo(User, 'user_id'),
            organization: this.belongsTo(Organization, 'organization_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast,
            start_date: DateCast,
            end_date: DateCast
        }
    }
}
