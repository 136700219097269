<script setup>
import { defineProps, defineEmits } from 'vue'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { PlusIcon } from 'lucide-vue-next'

defineProps({
  icon: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  buttonText: {
    type: String,
    default: 'Start nieuw'
  },
  showButton: {
    type: Boolean,
    default: false
  },
  marginBottom: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['new']);
</script>

<template>
  <div class="flex flex-row flex-shrink-0 mx-4 mt-4 ml-6 justify-between items-center flex-none min-h-[42px]">
    <div class="flex-row flex items-center space-x-3">
      <component :is="icon" />
      <h1 class="text-xl font-bold">{{ title }}</h1>
    </div>
    <Button v-if="showButton" @click="emit('new')" variant="alternative"
      class="gradient-border bg-backgroundSecondary hover:bg-accent relative shadow-sm justify-start space-x-2">
      <PlusIcon :stroke-width="1.8" class="h-5 w-5 text-primary" />
      <p class="font-medium text-primary z-10">
        {{ buttonText }}
      </p>
    </Button>
  </div>
  <Separator :class="marginBottom ? 'my-4 flex-none' : 'mt-4 flex-none'" />
</template>
