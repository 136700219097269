<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps(['question'])
const { question } = toRefs(props)
function sizing(n) {
    if (n <= 400) {
        return 'text-2xl'
    } else if (n <= 600) {
        return 'text-xl'
    } else {
        return 'text-lg'
    }
}

</script>

<template>
    <div class="flex flex-row justify-between items-start space-x-10">
        <div class="lg:px-0 justify-start text-left">
            <h2
            :class="`scroll-m-20 pb-2 ${sizing(question?.length || 0)} font-semibold tracking-tight transition-colors first:mt-0`">
            {{ question }}
            </h2>
        </div>  
    </div>
</template>