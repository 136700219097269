import { ref } from 'vue';
import { useDocumentStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';

export function useFileUpload(options = {}) {
    const {
        acceptedFileTypes = ['.txt', '.docx', '.pdf', '.md'],
        onUploadStart,
        onUploadProgress,
        onUploadComplete,
        onUploadError,
        onFilesLengthChange
    } = options;

    const dms = useDocumentStore();
    const isUploading = ref(false);
    const isFolderUpload = ref(false);
    const selectedFiles = ref([]);
    const uploadProgress = ref(0);
    const filesLength = ref(0);
    const {subFolders} = storeToRefs(dms);
    const isDragging = ref(false);
    let dragCounter = 0;

    const debouncedSetDragging = debounce((value) => {
        isDragging.value = value;
    }, 50);

    function isAcceptedFileType(file) {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        return acceptedFileTypes.includes(extension);
    }

    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    function createFolderStructure(files) {
        const structure = { name: 'root', type: 'folder', children: {} };
        let totalItems = 0;
        files.forEach(file => {
            const parts = file.webkitRelativePath.split('/');
            let currentLevel = structure.children;
            parts.forEach((part, index) => {
                if (index === parts.length - 1) {
                    currentLevel[part] = { type: 'file', file };
                    totalItems++;
                } else {
                    if (!currentLevel[part]) {
                        currentLevel[part] = { type: 'folder', name: part, children: {} };
                        totalItems++;
                    }
                    currentLevel = currentLevel[part].children;
                }
            });
        });
        return { structure, totalItems };
    }

    async function uploadFolderStructure(structure, parentId = null, totalItems, processedItems = { count: 0 }) {
        let rootFolder;

        // Upload folders first
        for (const [name, item] of Object.entries(structure.children)) {
            if (item.type === 'folder') {
                const isRootFolder = !parentId;
                if (isRootFolder) {
                    rootFolder = await dms.createFolder(name, [], parentId, true);
                    item.id = rootFolder.id;
                } else {
                    const newFolder = await dms.createFolder(name, [], parentId, true);
                    item.id = newFolder.id;
                }
                processedItems.count++;
                updateFolderProgress(processedItems.count, totalItems);
                await uploadFolderStructure(item, item.id, totalItems, processedItems);
            }
        }

        // Then upload files
        for (const item of Object.values(structure.children)) {
            if (item.type === 'file') {
                const fileContent = await readFileAsBase64(item.file);
                await dms.uploadDocument(item.file, fileContent, parentId, true);
                processedItems.count++;
                updateFolderProgress(processedItems.count, totalItems);
            }
        }

        // Add the root folder to subFolders after all uploads are complete
        if (rootFolder) {
            subFolders.value = [...subFolders.value, rootFolder];
        }
    }

    function updateFolderProgress(processedItems, totalItems) {
        const progress = Math.round((processedItems / totalItems) * 100);
        uploadProgress.value = progress;
        onUploadProgress?.(progress);
    }

    function decrementFilesLength() {
        filesLength.value--;
        onFilesLengthChange?.(filesLength.value);
    }

    function onDragEnter(event) {
        event.preventDefault();
        event.stopPropagation();
        dragCounter++;
        debouncedSetDragging(true);
    }

    function onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        dragCounter--;
        if (dragCounter === 0) {
            debouncedSetDragging(false);
        }
    }

    function onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    function onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        isDragging.value = false;
        dragCounter = 0;
        debouncedSetDragging.cancel();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFileSelection(files);
        }
    }

    async function handleFileSelection(files, parentId = null) {
        const fileList = Array.from(files);
        isFolderUpload.value = !!fileList[0]?.webkitRelativePath;

        selectedFiles.value = fileList.filter(file => isAcceptedFileType(file));

        if (selectedFiles.value.length === 0) {
            return;
        }

        isUploading.value = true;
        uploadProgress.value = 0;
        if (!isFolderUpload.value) {
            filesLength.value = selectedFiles.value.length;
            onFilesLengthChange?.(filesLength.value);
        }
        onUploadStart?.();

        try {
            if (isFolderUpload.value) {
                const { structure, totalItems } = createFolderStructure(selectedFiles.value);
                await uploadFolderStructure(structure, parentId, totalItems);
            } else {
                for (const file of selectedFiles.value) {
                    const fileContent = await readFileAsBase64(file);
                    await dms.uploadDocument(file, fileContent, parentId);
                    decrementFilesLength();
                }
            }
            
            onUploadComplete?.();
        } catch (error) {
            onUploadError?.(error);
        } finally {
            isUploading.value = false;
            selectedFiles.value = [];
            uploadProgress.value = 0;
            filesLength.value = 0;
            onFilesLengthChange?.(filesLength.value);
            isFolderUpload.value = false; // Reset folder upload state
        }
    }

    return {
        isUploading,
        selectedFiles,
        uploadProgress,
        handleFileSelection,
        acceptedFileTypes,
        filesLength,
        isFolderUpload,
        isDragging,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop
    };
}
