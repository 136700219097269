<script setup>
import { ref, computed, onMounted, nextTick, defineProps, toRefs } from 'vue'
import UserQuery from '@/components/sidepanel/UserQuery.vue'
import { Answer, Steps } from '@/components/run'

const props = defineProps(['run', 'isLast'])
const { run, isLast } = toRefs(props)

const progress = computed(() => {
  if (run.value?.suggested_follow_ups?.length > 0) return 3;
  if (run.value?.answer) return 2;
  if (run.value?.sources?.cases?.length > 0 || run.value.sources?.consolidations?.length > 0) return 1;
  return 0;
})

const loading = computed(() => {
  return run.value?.answer === null
})

const cls = computed(() => {
  let c = ''
  if (isLast.value) c += ' min-h-screen bg-background '
  return c
})
const scrollContainer = ref(null);
const scrollIntoView = () => {
  nextTick(() => {
    if (scrollContainer.value && isLast && progress.value <= 3) {
      scrollContainer.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });
};

onMounted(
  () => {
    if (progress.value <= 2) {
      scrollIntoView();
    }

  }
)
</script>

<template>
    <div ref="scrollContainer" :class="cls" class="flex flex-col space-y-4">
        <UserQuery class="mt-2" :query="run?.query"/>
        <Steps :status="run?.status" :progress="progress" :plan="run?.plan" />
        <div class="flex flex-row w-full">
            <div class="w-full flex justify-start">
                <div class="flex items-start space-x-1 ">
  
                    <div class="w-9 h-9 bg-no-repeat bg-contain bg-center mx-auto flex-shrink-0"
                      style="background-image: var(--symbol);"></div>
                     
                    <Steps v-if="loading" :run="run" class="ml-8 w-full"/>
                    <div class="bg-background rounded-lg w-full rounded-tl-none mt-2 pl-1">
                     
                      <Answer :answer="run?.answer" :sources="run?.sources" :runId="run?.id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>