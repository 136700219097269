// useReview.js
import { onBeforeMount, watch, ref, computed } from 'vue';
import { Document, DocumentPart } from '@/models';
import { useRoute, useRouter } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios'
import { api } from '@/plugins/api';

export function useDocument(id) {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(true)
    const repo = useAxiosRepo(Document)
    const documentPartRepo = useAxiosRepo(DocumentPart).setAxios(api)
    const document = computed(() => {
        return repo.withAllRecursive().find(id)
    })

    async function enter() {
        isLoading.value = true
        if (!document.value) {
            router.push({ name: 'home' })
            return;
        }
        if (!document.value.parts?.length) {
            await documentPartRepo.api().get(`/documents/${id}/parts`)
        }
        isLoading.value = false
    }

    onBeforeMount(enter)
    watch(route, enter)

    return { document, isLoading }
}