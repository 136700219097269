<script setup>
import { watch, defineProps, defineExpose, onMounted, onBeforeUnmount, computed, defineModel, toRefs } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Heading from '@tiptap/extension-heading'
import { Markdown } from 'tiptap-markdown'
import { Comments } from './CommentExtension.js'

const selectedCommentId = defineModel()
const props = defineProps(["document"])
const { document } = toRefs(props)

const content = computed(() => {
    return document.value.parts.map(part => part.page_content).join('\n')
})

const editor = useEditor({
    editorProps: {
        attributes: {
            class: 'text-left my-8 py-8 px-12 focus:outline-none text-primary mx-auto bg-background max-w-[90%] 2xl:max-w-[75%] h-full overflow-y-auto border-border border shadow-xl'
        }
    },
    content: content.value,
    extensions: [
        StarterKit,
        Heading.configure({
            levels: [1, 2, 3, 4]
        }),
        ListItem,
        OrderedList,
        BulletList,
        Markdown.configure({
            breaks: true
        }),
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Highlight.configure({ multicolor: true }),
        Comments
    ],
    editable: false,
    onCreate: ({ editor }) => {
        document.value.comments.forEach((comment, index) => {
            const content = editor.state.doc.textContent
            const selectionStart = content.indexOf(comment.selection)
            const selectionEnd = selectionStart + comment.selection.length
            editor.commands.setTextSelection({
                from: selectionStart,
                to: selectionEnd + 1
            })
            editor.commands.setMark("comment", { id: comment.id, number: index + 1 })
        })
    },
    onSelectionUpdate: ({ editor }) => {
        const { from, to } = editor.state.selection
        const marks = editor.state.doc.rangeHasMark(from, to, editor.schema.marks.comment)
        selectedCommentId.value = marks?.attrs?.id
    },
})

onMounted(() => {
    if (editor.value) {
        editor.value.on('transaction', ({ transaction }) => {
            const commentClicked = transaction.getMeta('commentClicked')
            if (commentClicked) selectedCommentId.value = commentClicked.id
        })
    }
})

onBeforeUnmount(() => {
    editor.value?.destroy()
})

watch(() => content.value, (newContent) => {
    if (newContent !== editor.value.getJSON()) {
        editor.value.commands.setContent(newContent, false)
    }
})

defineExpose({ editor })
</script>

<template>
    <div v-if="content"
        class="flex flex-col h-full overflow-hidden w-full  editor-content justify-center items-center align-middle">
        <div class="flex-grow  overflow-y-auto w-full mx-auto">
            <div class="flex mx-auto ">
                <EditorContent :editor="editor" class="flex-grow w-full" />
            </div>
        </div>
    </div>
</template>

<style>
/* Basic editor styles */
.tiptap {

    /* Use direct child selector for first-child */
    >*:first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 1.5rem;
        /* Increased left margin */
    }

    ul {
        list-style-type: disc;
        /* Add bullet points for unordered lists */
    }

    ol {
        list-style-type: decimal;
        /* Add numbers for ordered lists */
    }

    ul li,
    ol li {
        margin-bottom: 0.3em;
        /* Add some space between list items */
    }

    ul li p,
    ol li p {
        margin-top: 0.1em;
        margin-bottom: 0.1em;
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.3;
        text-wrap: pretty;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
    }

    h1,
    h2 {
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 0.8rem;
    }

    p {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    mark {
        background-color: #fa9fa5;
        border-radius: 0.1rem;
        box-decoration-break: clone;

    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: 'JetBrainsMono', monospace;
        margin: 1rem 0;
        padding: 0.75rem 1rem;
    }

    pre code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
    }

    blockquote {
        border-left: 3px solid var(--gray-3);
        margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 1rem 0;
    }
}

/* New styles for comments */
.ProseMirror .tiptap-comment {
    background-color: rgba(255, 212, 0, 0.2);
    border-bottom: 2px solid rgba(255, 212, 0, 0.5);
    cursor: pointer;
}

.ProseMirror .comment-number {
    font-size: 0.7em;
    font-weight: bold;
    background-color: rgba(255, 212, 0, 0.5);
    color: #333;
    padding: 0 4px;
    border-radius: 8px;
    vertical-align: super;
    margin-left: 2px;
}

.comment-number-card {
    font-size: 0.9em;
    font-weight: bold;
    background-color: rgba(255, 212, 0, 0.5);
    color: #333;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 2px;
}


.comment-item {
    transition: all 0.2s ease;
}

/* .comment-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

/* Add these new styles for better responsiveness */
.ProseMirror {
    min-height: 100%;
    max-height: none;
    /* Remove the max-height constraint */
    overflow-y: visible;
    /* Let the parent container handle scrolling */
}

.comment-item,
.chat-item {
    max-width: 100%;
    word-wrap: break-word;
}

/* Add this to your existing styles */
.tiptap {
    height: 100%;
    overflow-y: auto;
}

/* Custom scrollbar styles */
.EditorContent::-webkit-scrollbar {
    width: 8px;
}

.EditorContent::-webkit-scrollbar-track {
    background: transparent;
}

.EditorContent::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.EditorContent::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.chat-item {
    width: 100%;
}

.zeno-message,
.user-message {
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.user-message {
    float: right;
    clear: both;
}

/* New styles for fade-out effect */
.editor-container {
    position: relative;
}

.editor-content {
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 55px);
    /* Adjust this value as needed */
}

.editor-content::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(to bottom,
            hsl(var(--background) / 0) 0%,
            hsl(var(--background)) 100%);
    pointer-events: none;
}
</style>
