<script setup>
import { Input } from '@/components/ui/input';
import { SearchIcon } from 'lucide-vue-next';
import FeatureHeader from '@/components/FeatureHeader.vue';
import ListItems from './ListItems.vue';
import { defineProps, defineEmits } from 'vue';
defineProps({
    model: {
        type: Object,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    headerIcon: {
        type: Object,
        required: true
    },
    listIcon: {
        type: Object,
        required: true
    },
    buttonText: {
        type: String,
        default: 'Start nieuw'
    },
    searchPlaceholder: {
        type: String,
        default: 'Zoek...'
    },
    emptyPlaceholderParagraph: {
        type: String,
        default: 'Je hebt nog geen items gemaakt.'
    },
    emptyPlaceholderHeader: {
        type: String,
        default: 'Geen items gevonden'
    },
});
const emit = defineEmits(['new', 'click', 'update', 'delete']);
</script>

<template>
    <div class="h-[calc(100vh-55px)] overflow-hidden bg-background flex flex-col">
        <FeatureHeader :title="title" :buttonText="buttonText" :showButton="true" @new="emit('new', $event)"
            :icon="headerIcon">
        </FeatureHeader>
        <div class="flex flex-row justify-start items-start space-x-2 hidden pb-4 mx-4 flex-none">
            <div class="flex-1 items-center justify-center rounded-lg relative">
                <SearchIcon class="absolute left-4 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input type="search" :placeholder="searchPlaceholder"
                    class="h-12 pl-10 [&::-webkit-search-cancel-button]:hidden [&::-ms-clear]:hidden rounded-lg" />
            </div>
        </div>
        <div class="flex-1 overflow-y-auto px-4  bg-background h-full">
            <ListItems :model="model" :icon="listIcon" @click="emit('click', $event)" @update="emit('update', $event)"
                @delete="emit('delete', $event)" :emptyPlaceholderHeader="emptyPlaceholderHeader"
                :emptyPlaceholderParagraph="emptyPlaceholderParagraph" :emptyIcon="headerIcon"
                @new="emit('new', $event)" />
        </div>
    </div>
</template>
