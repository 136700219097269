<script setup>
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Pencil, Trash2, Search } from 'lucide-vue-next';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ref, defineModel, defineEmits, defineProps, toRefs } from 'vue';

const props = defineProps({
    prompts: { type: Array, required: true }
});
const { prompts } = toRefs(props);

const isOpen = defineModel('open', { type: Boolean, required: true });
const currentEditedValue = ref('');
const currentEditingIndex = ref(null);
const emit = defineEmits(['new', 'update', 'delete']);


function startEditingPrompt(prompt = null) {
    if (prompt) {
        currentEditingIndex.value = prompts.value.findIndex(p => p.id === prompt.id);
        currentEditedValue.value = prompt.content;
    } else {
        currentEditingIndex.value = prompts.value.length;
        currentEditedValue.value = '';
    }
}


function saveEditedPrompt(closeDialog = true) {
    if (currentEditingIndex.value !== null && currentEditedValue.value.trim()) {
        if (currentEditingIndex.value >= prompts.value.length) {
            emit('new', currentEditedValue.value.trim());
        } else {
            const prompt = prompts.value[currentEditingIndex.value];
            prompt.content = currentEditedValue.value.trim();
            emit('update', prompt);
        }
    }
    currentEditingIndex.value = null;
    currentEditedValue.value = '';
    if (closeDialog) {
        isOpen.value = false;
    }
}

function newPrompt() {
    emit('new', currentEditedValue.value.trim());
    currentEditedValue.value = '';
}
</script>


<template>
    <Dialog :open="isOpen" @update:open="isOpen = false" class="w-full max-w-md mx-auto">
        <DialogContent class="bg-backgroundSecondary">
            <DialogHeader>
                <DialogTitle>Beheer je prompts</DialogTitle>
            </DialogHeader>
            <div class="py-2">
                <div class="flex flex-col space-y-4">
                    <!-- Input Section -->
                    <div class="flex items-center space-x-2">
                        <Input v-model="currentEditedValue" placeholder="Schrijf een prompt" @keyup.enter="newPrompt" />
                        <Button @click="newPrompt" variant="outline" size="icon" class="text-muted-foreground">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-corner-down-left">
                                <polyline points="9 10 4 15 9 20" />
                                <path d="M20 4v7a4 4 0 0 1-4 4H4" />
                            </svg>
                        </Button>
                    </div>
                    <!-- Prompts List -->
                    <div class="max-h-60 overflow-y-auto py-2 pr-3 pl-2 border-border border max-w-full rounded-lg">
                        <div class="space-y-2">
                            <div v-for="(prompt, index) in prompts" :key="prompt.id"
                                class="flex items-center max-w-full px-2 py-1 border border-border bg-background rounded-lg">
                                <div v-if="currentEditingIndex === index"
                                    class="flex-grow flex items-center space-x-2 min-w-0 mr-2">
                                    <Input v-model="currentEditedValue" @keyup.enter="saveEditedPrompt(false)"
                                        @blur="saveEditedPrompt(false)" class="text-xs" autofocus />
                                </div>
                                <div v-else class="flex-grow flex items-center space-x-2 min-w-0 mr-2">
                                    <Search class="h-3 w-3 text-muted-foreground flex-shrink-0" />
                                    <p class="text-xs break-all whitespace-pre-wrap my-auto">{{ prompt.content }}</p>
                                </div>
                                <div class="flex items-center">
                                    <Button @click="startEditingPrompt(prompt)" variant="ghost" size="icon"
                                        class="h-6 w-6 flex-shrink-0">
                                        <Pencil class="h-3 w-3" />
                                    </Button>
                                    <Button @click="emit('delete', prompt.id)" variant="ghost" size="icon"
                                        class="h-6 w-6 flex-shrink-0">
                                        <Trash2 class="h-3 w-3" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogFooter>
                <Button @click="isOpen = false" variant="default">Sluiten</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>