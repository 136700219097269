<!-- FileUpload.vue -->
<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { FileIcon, FolderIcon } from 'lucide-vue-next';
import { useFileUpload } from '@/composables/upload';
import { ActionCard } from '@/components/documents';

const props = defineProps({
    parentId: {
        type: String,
        default: null
    },
    acceptedFileTypes: {
        type: Array,
        default: () => ['.txt', '.docx', '.pdf', '.md']
    },
});

const emit = defineEmits(['uploadStart', 'uploadProgress', 'uploadComplete', 'uploadError', 'filesLengthChange', 'folderUpload']);

const {
    handleFileSelection,
    isFolderUpload
} = useFileUpload({
    acceptedFileTypes: props.acceptedFileTypes,
    onUploadStart: () => emit('uploadStart'),
    onUploadComplete: () => emit('uploadComplete'),
    onUploadError: (error) => emit('uploadError', error),
    onFilesLengthChange: (length) => emit('filesLengthChange', length),
    onUploadProgress: (progress) => emit('uploadProgress', progress)
});

// Add a watch to emit the isFolderUpload value when it changes
watch(isFolderUpload, (newValue) => {
    emit('folderUpload', newValue);
});

const fileInput = ref(null);

function triggerFileInput(type) {
    if (type === 'folder') {
        fileInput.value.setAttribute('webkitdirectory', '');
        fileInput.value.setAttribute('directory', '');
        isFolderUpload.value = true;
    } else {
        fileInput.value.removeAttribute('webkitdirectory');
        fileInput.value.removeAttribute('directory');
        isFolderUpload.value = false;
    }
    fileInput.value.click();
}

function onFileSelect(event) {
    handleFileSelection(event.target.files, props.parentId);
}
</script>

<template>
    <div>
        <input
            type="file"
            ref="fileInput"
            @change="onFileSelect"
            class="hidden"
            :accept="acceptedFileTypes.join(',')"
            multiple
        />
        <div class="flex flex-row w-full space-x-2">
            <ActionCard
                :icon="FileIcon"
                label="Upload bestanden"
                :onClick="() => triggerFileInput('file')"
                :upload="true"
            />
            <ActionCard
                :icon="FolderIcon"
                label="Upload dossier"
                :onClick="() => triggerFileInput('folder')"
                :upload="true"
            />
        </div>
    </div>
</template>
