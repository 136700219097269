<script setup>
import { defineProps, computed } from 'vue';
import { Zap } from 'lucide-vue-next';
import { Progress } from '@/components/ui/progress';

const props = defineProps({
    subscription: Object
});

const limit = computed(() => {
    return props.subscription.usage?.search?.limit || 100
})

const remaining = computed(() => {
    return (props.subscription.usage?.search?.limit - props.subscription.usage?.search?.used) || 100
})
</script>

<template>
    <div class="flex flex-col space-y-2 p-1 px-2">
        <div class="flex flex-row space-x-2 items-center text-primary justify-between">
            <Zap width="15" height="15" />
            <span class="text-xs text-left font-medium">
                Credits
            </span>
        </div>
        <Progress :modelValue="remaining" :max="limit" class="h-[8px]" />
    </div>
</template>