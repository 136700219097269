<script setup>
import { cn } from '@/lib/utils';
import { GripVertical } from 'lucide-vue-next';
import { SplitterResizeHandle, useForwardPropsEmits } from 'radix-vue';
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  id: { type: String, required: false },
  hitAreaMargins: { type: Object, required: false },
  tabindex: { type: Number, required: false },
  disabled: { type: Boolean, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
  withHandle: { type: Boolean, required: false },
});
const emits = defineEmits(['dragging']);

const delegatedProps = computed(() => {
  // eslint-disable-next-line
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <SplitterResizeHandle
    v-bind="forwarded"
    :class="
      cn(
        'relative flex w-px items-center justify-center bg-border after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 [&[data-orientation=vertical]]:h-px [&[data-orientation=vertical]]:w-full [&[data-orientation=vertical]]:after:left-0 [&[data-orientation=vertical]]:after:h-1 [&[data-orientation=vertical]]:after:w-full [&[data-orientation=vertical]]:after:-translate-y-1/2 [&[data-orientation=vertical]]:after:translate-x-0 [&[data-orientation=vertical]>div]:rotate-90',
        props.class,
      )
    "
  >
    <template v-if="props.withHandle">
      <div
        class="z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-muted"
      >
        <GripVertical class="h-2.5 w-2.5" />
      </div>
    </template>
  </SplitterResizeHandle>
</template>
