import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'

export class Prompt extends Model {
    static entity = 'prompts'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            content: this.string(''),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
