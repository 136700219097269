<script setup>
import { useSubscriptionStore } from '@/stores/subscription';
import { onMounted } from 'vue';
import { FileSearch2, Send, Sparkles, LifeBuoy, Wand2, Vault, Sheet as SheetIcon, TextQuote, BookMarked, ArrowUpRight, FileCheck, FileSpreadsheetIcon } from 'lucide-vue-next';
import { useSidebar, Sidebar, SidebarHeader, SidebarContent, SidebarFooter, SidebarMenu, SidebarMenuItem, SidebarMenuButton, SidebarRail, SidebarGroup, SidebarGroupContent, SidebarMenuAction } from '@/components/ui/sidebar';
import { User, Building, ChevronsUpDown, Plus } from 'lucide-vue-next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger, DialogClose, DialogFooter } from '@/components/ui/dialog';
import { Settings } from '@/components/settings';
import Upgrade from '@/components/Upgrade2.vue';
import { useRoute, useRouter } from 'vue-router';
import { Thread, Review, Sheet, Organization } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios'
import { SidebarResourceList, UsageMeter, Feedback, Help } from '.';
import CreateOrganizationForm from './CreateOrganizationForm.vue';
import { useScope } from '@/composables';
import { useAuth } from '@/auth';
import { api } from '@/plugins/api';

const route = useRoute();
const router = useRouter();
const { open } = useSidebar()

const { refreshAccessToken } = useAuth();
const { user, organization } = useScope();

const subscription = useSubscriptionStore();

onMounted(async () => {
    await subscription.fetchUsage();
});

const organizationRepo = useAxiosRepo(Organization).setAxios(api);
</script>

<template>
    <Sidebar collapsible="icon">
        <SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem>
                    <SidebarMenuButton v-if="true" size="lg" @click="router.push({ name: 'home' })">
                        <Avatar class="size-8 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                            style="background-image: var(--symbol);">
                            <AvatarFallback />
                        </Avatar>
                        <div class="grid flex-1 text-left text-sm leading-tight">
                            <span class="truncate font-semibold">{{ organization?.name || 'Personal' }}</span>
                            <span class="truncate text-xs text-gradient">{{
                                subscription.plan.charAt(0).toUpperCase() + subscription.plan.slice(1) }}</span>
                        </div>
                    </SidebarMenuButton>
                    <DropdownMenu v-else>
                        <DropdownMenuTrigger as-child>
                            <SidebarMenuButton size="lg">
                                <Avatar class="size-8 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                                    style="background-image: var(--symbol);">
                                    <AvatarFallback />
                                </Avatar>
                                <div class="grid flex-1 text-left text-sm leading-tight">
                                    <span class="truncate font-semibold">{{ organization?.name || 'Personal' }}</span>
                                    <span class="truncate text-xs text-gradient">{{
                                        subscription.plan.charAt(0).toUpperCase() + subscription.plan.slice(1) }}</span>
                                </div>
                                <ChevronsUpDown class="ml-auto" />
                            </SidebarMenuButton>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                            align="start" side="bottom" :side-offset="4">
                            <DropdownMenuItem v-if="organization?.id" @click="refreshAccessToken(null)"
                                class="space-x-2">
                                <User size="16" />
                                <span>Personal</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem v-for="org in user.organizations.filter(o => o.id !== organization?.id)"
                                :key="org.id" @click="refreshAccessToken(org.id)" class="space-x-2">
                                <Building size="16" />
                                <span>{{ org.name }}</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem class="gap-2 p-2">
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <div class="flex items-center gap-2 cursor-pointer" @click.stop>
                                            <div
                                                class="flex size-6 items-center justify-center rounded-md border bg-background">
                                                <Plus class="size-4" />
                                            </div>
                                            <div class="font-medium text-muted-foreground">
                                                Create organization
                                            </div>
                                        </div>
                                    </DialogTrigger>
                                    <DialogContent class="sm:max-w-[425px]">
                                        <DialogHeader>
                                            <DialogTitle>Create Organization</DialogTitle>
                                            <DialogDescription>
                                                Create a new organization to collaborate with your team.
                                            </DialogDescription>
                                        </DialogHeader>
                                        <CreateOrganizationForm
                                            @submit="organizationRepo.api().post('/organizations/', $event)">
                                            <DialogFooter>
                                                <DialogClose asChild>
                                                    <Button variant="outline">Cancel</Button>
                                                </DialogClose>
                                                <Button type="submit">
                                                    Submit
                                                </Button>
                                            </DialogFooter>
                                        </CreateOrganizationForm>
                                    </DialogContent>
                                </Dialog>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
            <SidebarGroup>
                <SidebarGroupContent>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <SidebarMenuButton tooltip="Assistent" @click="router.push({ name: 'home' })"
                                :is-active="route.name === 'home'">
                                <Wand2 />
                                <span>Assistent</span>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                        <SidebarMenuItem class="vault-sidebar-tutorial-ref">
                            <SidebarMenuButton tooltip="Vault" @click="router.push({ name: 'documents' })"
                                :is-active="route.name === 'documents' || route.name === 'document'"
                                :disabled="subscription.plan === 'free'">
                                <Vault />
                                <span>Vault</span>
                            </SidebarMenuButton>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                        </SidebarMenuItem>
                        <SidebarMenuItem class="sheets-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="SheetIcon" :itemIcon="FileSpreadsheetIcon" label="Sheets"
                                route-name="sheetList" item-route-name="sheet" :model="Sheet"
                                :disabled="subscription.plan === 'free'" />
                        </SidebarMenuItem>
                        <SidebarMenuItem class="review-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="TextQuote" :itemIcon="FileCheck" label="Reviews"
                                route-name="reviewList" item-route-name="review" :model="Review"
                                :disabled="subscription.plan === 'free'" />
                        </SidebarMenuItem>
                        <SidebarMenuItem class="research-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="BookMarked" :itemIcon="FileSearch2" label="Research"
                                route-name="threadList" item-route-name="thread" :model="Thread" />
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
            <SidebarMenu>
                <SidebarMenuItem>
                    <Help>
                        <SidebarMenuButton tooltip="Help" class="text-sm">
                            <LifeBuoy />
                            <span>Help</span>
                        </SidebarMenuButton>
                    </Help>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <Feedback>
                        <SidebarMenuButton tooltip="Feedback" class="text-sm">
                            <Send/>
                            <span>Feedback</span>
                        </SidebarMenuButton>
                    </Feedback>
                </SidebarMenuItem>
                <SidebarMenuItem v-if="subscription.plan === 'free'">
                    <Upgrade>
                        <SidebarMenuButton tooltip="Upgrade">
                            <Sparkles />
                            <span>Upgrade</span>
                        </SidebarMenuButton>
                    </Upgrade>
                </SidebarMenuItem>
                <SidebarMenuItem v-if="subscription.plan === 'free' && open">
                    <UsageMeter :subscription="subscription" />
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <Settings as-child>
                        <SidebarMenuButton tooltip="Settings" size="lg">
                            <Avatar class="size-8 rounded-lg">
                                <AvatarImage :src="user.profile_picture_url" :alt="user.first_name" />
                                <AvatarFallback>
                                    {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
                                </AvatarFallback>
                            </Avatar>
                            <div class="grid flex-1 text-left text-sm leading-tight">
                                <span class="truncate font-semibold">{{ user.first_name + ' ' + user.last_name }}</span>
                                <span class="truncate text-xs">{{ user.email }}</span>
                            </div>
                            <ArrowUpRight class="ml-auto size-4" />
                        </SidebarMenuButton>
                    </Settings>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
    </Sidebar>
</template>

<style scoped>
.bg-gradient {
    background: linear-gradient(to right, #000000, #000000);
}
</style>