import { ref } from 'vue';

const isOpen = ref(false);
const resolvePromise = ref(null);
const message = ref('');

export function useConfirm() {
  const open = (msg) => {
    message.value = msg;
    isOpen.value = true;
    return new Promise((resolve) => {
      resolvePromise.value = resolve;
    });
  };

  const confirm = () => {
    isOpen.value = false;
    resolvePromise.value(true);
  };

  const cancel = () => {
    isOpen.value = false;
    resolvePromise.value(false);
  };

  return {
    isOpen,
    message,
    open,
    confirm,
    cancel,
  };
}

