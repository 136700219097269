<script setup>
import { ref, inject } from 'vue';
import { Button } from '@/components/ui/button';
import { toast } from 'vue-sonner';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Textarea } from '@/components/ui/textarea'
import { useAuth } from '@/auth';

const { user } = useAuth()
const feedback = ref('');

const posthog = inject("posthog")

async function submit() {
  const val = feedback.value.trim();
  if (!val) {
    toast.error('Vul alsjeblieft je feedback in voordat je het instuurt.');
    return;
  }
  try {
    posthog.identify(user.id, { email: user.email, first_name: user.first_name, last_name: user.last_name })
    posthog.capture(
      'survey sent',
      {
        $survey_id: '0191c77c-27dc-0000-359b-ecb6e4a354f3',
        $survey_response: val,
      }
    )
    feedback.value = '';
    toast.success('Bedankt voor je feedback!');
  } catch (error) {
    toast.error('Er is iets misgegaan bij het versturen van de feedback, probeer het opnieuw.');
  }
}
</script>

<template>
  <AlertDialog>
    <AlertDialogTrigger class="w-full" as-child>
      <slot />
    </AlertDialogTrigger>
    <AlertDialogContent>
      <div class="absolute top-4 right-4">
        <AlertDialogCancel class="p-1 hover:bg-background hover:text-secondary border-none rounded-full">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
              fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
          </svg>
        </AlertDialogCancel>
      </div>
      <AlertDialogHeader>
        <AlertDialogTitle>Geef hier jouw feedback</AlertDialogTitle>
        <AlertDialogDescription class="flex flex-col gap-4">
          <p class="text-sm">
            Jouw feedback is van onschatbare waarde voor ons. Het stelt ons in staat om de app voortdurend te verbeteren
            en te optimaliseren.
            Heb je naast feedback ook een briljant idee? Geen probleem! Je kunt beide hier delen.
          </p>
          <Textarea v-model="feedback" placeholder="Typ hier jouw feedback" class="border rounded-lg" />
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Annuleren</AlertDialogCancel>
        <AlertDialogAction asChild>
          <Button @click="submit" variant="default">
            Inzenden
          </Button>
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>

</template>
