<script setup>
import { defineProps } from 'vue';
import { cn } from '@/lib/utils'
import { Primitive } from 'radix-vue'
import { sidebarMenuButtonVariants } from '.'

const props = defineProps({
  variant: { type: String, default: 'default' },
  size: { type: String, default: 'default' },
  isActive: { type: Boolean, default: false },
  class: { type: String, default: '' },
  asChild: { type: Boolean, default: false },
  as: { type: String, default: 'button' }
})
</script>

<template>
  <Primitive data-sidebar="menu-button" :data-size="size" :data-active="isActive"
    :class="cn(sidebarMenuButtonVariants({ variant, size }), props.class)" :as="as" :as-child="asChild" v-bind="$attrs">
    <slot />
  </Primitive>
</template>
