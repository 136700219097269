import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const currentPath = ref('/');

// Custom mapping for route names to labels
const routeLabels = {
  home: 'Start',
  thread: 'Search',
  t: 'Search',
  document: 'Document',
  library: 'Bibliotheek',
  documents: 'Documenten',
  // Add more mappings as needed
};

export function useBreadcrumbStore() {
  const route = useRoute();
  const router = useRouter();

  const breadcrumbs = computed(() => {
    const breadcrumbItems = [];
    const matched = route.matched;

    matched.forEach((match) => {
      const routeName = match.name;
      const params = match.params || {};

      let label = routeLabels[routeName] || (routeName ? routeName.charAt(0).toUpperCase() + routeName.slice(1) : '');
      let path = router.resolve({ name: routeName, params }).path;

      breadcrumbItems.push({ label, path });
    });

    return breadcrumbItems;
  });

  function updatePath(newPath) {
    currentPath.value = newPath;
  }

  return {
    breadcrumbs,
    updatePath
  };
}
