<script setup>
import { useSubscriptionStore } from '@/stores/subscription';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import Upgrade from '@/components/Upgrade';
import { Sparkles } from 'lucide-vue-next';
import { PlanCard } from '@/components/settings/';
import { useAuth } from '@/auth';
const auth = useAuth();

const subscription = useSubscriptionStore();

const proFeatures = [
  'Alles van basic',
  'Search+',
  'Ongelimiteerde search credits',
  'Sheets',
  'Document review',
  'Document management'
];

</script>

<template>
    <div class="flex flex-col w-full h-full relative">
        <div class="flex flex-col space-y-1 ">
            <div class="flex flex-row items-center">
                <h2 class="mr-2 font-regular text-sm">Huidig abonnement: </h2>
                <Badge variant="outline" :class="[
                    'gradient-border px-1 mr-2 font-semibold rounded-lg',
                    subscription.plan === 'pro' ? 'gradient-border bg-muted   ' : 'border-border bg-accent text-muted-foreground'
                ]">
                    <p class="text-xs text-gradient"> {{ subscription.plan.toUpperCase() }}</p>
                </Badge>
            </div>
            <p class="text-sm text-muted-foreground" v-if="subscription.endDate">Geldig tot: {{ new Date(subscription.endDate).toLocaleDateString() }}</p>
        </div>
        <div class="grid grid-cols-1 py-4 gap-2">
            <PlanCard v-if="(subscription.plan === 'pro' || subscription.plan === 'free') && !auth.organization"
                :class="'bg-backgroundSecondary border-border border'"
                :plan="subscription.plan === 'free' ? 'Ontdek Pro' : 'Pro abonnement'"
                :features="proFeatures"
                :price="'€89,- p/maand'"
                :isFree="subscription.plan === 'free'"
            />
            <div v-else-if="subscription.plan === 'team' || (subscription.plan === 'free' && auth.organization)"
                class="flex flex-col space-y-4 bg-backgroundSecondary border-border border rounded-lg p-4">
                <div class="flex flex-col space-y-2">
                    <h3 class="text-lg font-semibold">Team abonnement</h3>
                    <p class="text-sm text-muted-foreground">Je bent onderdeel van een team abonnement.</p> 
                    <p class="text-xs text-muted-foreground">Prijs per gebruiker: €119,- p/maand</p>
                </div>
                <div class="flex flex-col space-y-2">
                    <div class="flex items-center justify-between">
                        <p class="text-sm text-muted-foreground">Aantal gebruikers</p>
                        <p class="text-sm font-medium">{{ subscription.quantity }} seats</p>
                    </div>
                    <div class="flex items-center justify-between">
                        <p class="text-sm text-muted-foreground">Totale kosten</p>
                        <p class="text-sm font-medium">€{{ subscription.quantity * 119 }},- p/maand</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="(subscription.plan === 'pro' || subscription.plan === 'free') && !auth.organization" class="flex flex-col space-x-2 w-full mt-auto">
            <Upgrade v-if="subscription.plan === 'free'" text="Upgrade naar Pro" :icon="Sparkles" />
            <Button v-if="subscription.plan !== 'free' && subscription.status === 'active'" variant="outline" size="" @click="subscription.updateSubscription({ plan: 'free' })">Opzeggen</Button>
            <Button v-if="subscription.plan !== 'free' && subscription.status === 'cancelled'" size="" @click="subscription.updateSubscription({ plan: subscription.plan, billing_period: subscription.billingPeriod, quantity: subscription.quantity })"><div class="flex items-center space-x-2"><Sparkles class="w-4 h-4 mr-2" />Heractiveer</div></Button>
        </div>
        <div v-else-if="subscription.plan === 'team' || (subscription.plan === 'free' && auth.organization)" class="flex flex-col space-x-2 w-full mt-auto">
            <p class="text-left text-sm text-muted-foreground">Neem contact op met ons om je abonnement te wijzigen.</p>
        </div>
        
    </div>
</template>