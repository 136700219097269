<script setup>
import { ref, computed, watch, defineProps, defineEmits, nextTick } from 'vue';
import { RangeCalendar } from '@/components/ui/range-calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { CalendarDate } from '@internationalized/date';
import { Calendar as CalendarIcon } from 'lucide-vue-next';
import { cn } from '@/lib/utils';

const props = defineProps(['modelValue', 'label_name']);
const emit = defineEmits(['update:modelValue']);

const dateRange = ref(props.modelValue || { start: null, end: null });
const key = ref(0); // Add this line to force re-render

const formattedDateRange = computed(() => {
  if (dateRange.value.start && dateRange.value.end) {
    return `${formatCalendarDate(dateRange.value.start)} - ${formatCalendarDate(dateRange.value.end)}`;
  } else if (dateRange.value.start) {
    return `Vanaf ${formatCalendarDate(dateRange.value.start)}`;
  } else if (dateRange.value.end) {
    return `Tot ${formatCalendarDate(dateRange.value.end)}`;
  }
  return '';
});

const formatCalendarDate = (date) => {
  if (date instanceof CalendarDate) {
    return `${date.day}-${date.month}-${date.year}`;
  }
  return '';
};

const updateDateRange = (newValue) => {
  dateRange.value = newValue;
  emit('update:modelValue', newValue);
};

const clearDateRange = () => {
  dateRange.value = { start: null, end: null };
  emit('update:modelValue', { start: null, end: null });
  nextTick(() => {
    key.value++; // Increment the key to force re-render
  });
};

watch(() => props.modelValue, (newValue) => {
  dateRange.value = newValue || { start: null, end: null };
}, { deep: true });
</script>

<template>
  <div>
    <Popover>
      <PopoverTrigger as-child>
        <Button variant="outline" :class="cn(
          'w-full sm:min-w-[230px] min-w-0 justify-start text-left font-normal h-10',
          !dateRange.start && !dateRange.end && 'text-muted-foreground'
        )">
          <CalendarIcon class="mr-2 h-4 w-4" />
          {{ formattedDateRange || props.label_name || 'Selecteer data' }}
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-auto p-0">
        <RangeCalendar :key="key" v-model="dateRange" :number-of-months="1" @update:model-value="updateDateRange" />
        <div class="px-2 mb-2">
          <Button @click="clearDateRange" variant="outline" class="w-full bg-backgroundSecondary">Reset</Button>
        </div>
      </PopoverContent>
    </Popover>
  </div>
</template>
