import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '@/auth'
import { inject } from 'vue';
import axios from 'axios';
import { 
  Home,
  NotFound,
  Thread,
  Documents,
  Document,
  Review,
  ReviewList,
  SheetList,
  Sheet,
  ThreadList
} from '@/views';

const guard = async to => {
  const { authGuard } = useAuth()
  await authGuard(to)
}

const login = (to) => {
  const { loginWithRedirect, organization } = useAuth()
  loginWithRedirect(to.query?.redirectPath || '/', organization.value?.id)
}

const callback = async () => {
  const { user, handleRedirectCallback } = useAuth()
  await handleRedirectCallback()
  const posthog = inject("posthog")
  posthog.identify(user.id, {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name
  });
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: guard
  },
  { path: '/:catchAll(.*)', component: NotFound },
  {
    path: '/document/:id',
    name: 'document',
    component: Document,
    beforeEnter: guard,
    meta: {
      breadcrumbs: [{ path: '/documents', label: 'Vault' }, { label: 'Document' }]
    }
  },
  {
    path: '/documents/:folderPath(.*)*',
    name: 'documents',
    component: Documents,
    beforeEnter: guard,
    props: route => ({ 
        folderPath: Array.isArray(route.params.folderPath) 
            ? route.params.folderPath.filter(Boolean)
            : (typeof route.params.folderPath === 'string')
                ? route.params.folderPath.split('/').filter(Boolean)
                : []
    }),
    meta: {
      breadcrumbs: [{ label: 'Vault' }]
    }
  },
  {
    path: '/threads',
    children: [
      {
        path: '',
        name: 'threadList',
        component: ThreadList,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ label: 'Research' }]
        }
      },
      {
        path: ':id',
        name: 'thread',
        component: Thread,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ path: '/threads', label: 'Research' }, { label: 'Thread' }]
        }
      }
    ]
  },
  {
    path: '/reviews',
    children: [
      {
        path: '',
        name: 'reviewList',
        component: ReviewList,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ label: 'Reviews' }]
        }
      },
      {
        path: ':id',
        name: 'review',
        component: Review,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ path: '/reviews', label: 'Reviews' }, { label: 'Review' }]
        }
      }
    ]
  },
  {
    path: '/sheets',
    children: [
      {
        path: '',
        name: 'sheetList',
        component: SheetList,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ label: 'Sheets' }]
        }
      },
      {
        path: ':id',
        name: 'sheet',
        component: Sheet,
        beforeEnter: guard,
        meta: {
          breadcrumbs: [{ path: '/sheets', label: 'Sheets' }, { label: 'Sheet' }]
        }
      }
    ]
  },
  { path: '/auth/login', redirect: login },
  { path: '/auth/callback',
    name: 'callback',
    beforeEnter: async (next) => {
      await callback();
      next('/');
    }
  },
  {
    name: 'checkout', path: '/orders/checkout', beforeEnter: async (to, from, next) => {
      try {
        const { useAuth } = require('@/auth');
        const { getAccessToken } = useAuth()
        const url = new URL(`${process.env.VUE_APP_API_URL}/orders/checkout`)
        const token = await getAccessToken();
        const response = await axios.post(url.toString(), to.query, { headers: { Authorization: `Bearer ${token}` } })
        if (response.data && response.data?.id === undefined) window.location.href = response.data;
        next('/');
      } catch (error) {
        console.error(error)
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
