<script setup>
import { SearchBar } from '@/components/searchbar';
import { Run } from '@/components/threads'
import { Loading } from '@/components';
import { useThread } from '@/composables'

const { thread, isLoading } = useThread()
</script>

<template>
    <div v-if="!isLoading && thread"
        class="mx-auto w-full max-w-4xl lg:w-screen xl:min-w-[90%] 2xl:min-w-[80%] flex flex-col h-full">
        <div class="flex flex-col mx-6 py-4">
            <Run v-for="(run, i) in thread?.runs" 
                :key="i" 
                :run="run" 
                :i="i" 
                :threadId="thread.id"
                :isLast="i == thread?.runs.length - 1" 
                class="w-full" />
                
            <div v-if="thread?.runs.length > 0" 
                class="sticky bottom-4 w-full sm:w-2/3">
                <SearchBar 
                    v-model="thread.options" 
                    size="sm" 
                    ctx="thread" 
                    :id="thread.id" />
            </div>
        </div>
    </div>
    <Loading v-else />
</template>