<script setup>
import { Button } from '@/components/ui/button'
import Upgrade from '@/components/Upgrade';
import { defineProps, toRefs, inject, ref } from 'vue'
import { useSearch } from '@/composables';
import { usePosthog } from '@/composables/analytics';

const { identifyUser } = usePosthog();
const props = defineProps(['icon', 'buttonText', 'query', 'ctx', 'id', 'closeDialog', 'followUpType'])
const { icon, buttonText, query, ctx, id, closeDialog, followUpType } = toRefs(props)

const open = ref(false)
const { text, sendMessage } = useSearch(ctx.value, id.value)

const posthog = inject('posthog');

const handleFollowUp = async () => {
  closeDialog.value() // Call closeDialog as a function
  text.value = query.value
  try {
    identifyUser()
    posthog.capture(`$user_triggered_${followUpType.value}`, { thread_id: id?.value, query: query?.value })
    await sendMessage()
  } catch (e) {
      if (e.message === 'Out of credits') {
        identifyUser()
        posthog.capture('$user_out_of_credits', { thread_id: id?.value })
        open.value = true;
      }
  }
}
</script>

<template>
  <Button 
    @click="handleFollowUp"
    variant="outline"
    class="w-full flex flex-row space-x-2 bg-backgroundSecondary"
  >
    <component :is="icon" class="w-4 h-4" />
    <p class="text-sm">{{ buttonText }}</p>
  </Button>
  <Upgrade :share="true" v-model="open" :no_button="true"/>
</template>