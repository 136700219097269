import { computed, onMounted, watch } from 'vue';
import { User, Organization, Subscription } from '@/models';
import { useAuth } from '@/auth';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useRepo } from 'pinia-orm';
import { api } from '@/plugins/api';

export function useScope() {
    const auth = useAuth();
    const userRepo = useAxiosRepo(User).setAxios(api)
    const organizationRepo = useAxiosRepo(Organization).setAxios(api)
    const subscriptionRepo = useRepo(Subscription)
    const user = computed(() => {
        return userRepo.withAllRecursive().find(auth.user.id)
    })
    const organization = computed(() => {
        return organizationRepo.withAllRecursive().find(auth.organization?.id)
    })
    const subscription = computed(() => {
        if (auth.organization) {
            return subscriptionRepo.where('organization_id', auth.organization.id).first()
        }
        return subscriptionRepo.where('user_id', auth.user.id).first()
    })

    watch(auth.organization, async () => {
        await Promise.all([
            !user.value ? userRepo.api().get('/users/') : Promise.resolve(),
            (!organization.value && auth.organization?.id) ? organizationRepo.api().get('/organizations/') : Promise.resolve()
        ])
        subscriptionRepo.save(auth.organization?.subscription || auth.user?.subscription)
    })

    onMounted(async () => {
        await Promise.all([
            !user.value ? userRepo.api().get('/users/') : Promise.resolve(),
            (!organization.value && auth.organization?.id) ? organizationRepo.api().get('/organizations/') : Promise.resolve()
        ])
        subscriptionRepo.save(auth.organization?.subscription || auth.user?.subscription)
    })

    return {
        user,
        organization,
        subscription
    }
}