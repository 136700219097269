<script setup>
import { cn } from "@/lib/utils";
import { defineProps } from "vue";

const props = defineProps({
  class: { type: null, required: false },
  rounded: { type: null, required: false },
  borderClass: { type: null, required: false },
});
</script>

<template>
  <div :class="cn('relative w-full overflow-auto', props.rounded, props.borderClass)">
    <table :class="cn('w-full caption-bottom text-sm', props.class)">
      <slot />
    </table>
  </div>
</template>
