import { Mark, mergeAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { DecorationSet, Decoration } from 'prosemirror-view'

export const Comments = Mark.create({
  name: 'comment',

  addAttributes() {
    return {
      id: {
        default: null
      },
      number: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="comment"]',
        getAttrs: element => ({
          id: element.getAttribute('data-id'),
          number: element.getAttribute('data-number')
        })
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(
      { 'data-type': 'comment' },
      HTMLAttributes,
      { class: 'tiptap-comment' }
    ), 0]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('comment'),
        props: {
          handleClick(view, pos) {
            const { state } = view
            const { doc } = state
            const resolvedPos = doc.resolve(pos)
            const mark = resolvedPos.marks().find(m => m.type.name === 'comment')
            if (mark) {
    
              view.dispatch(state.tr.setMeta('commentClicked', mark.attrs))
              return true
            }
            return false
          },
          decorations: (state) => {
            const { doc } = state
            const decorations = []

            doc.descendants((node, pos) => {
              const commentMark = node.marks.find(mark => mark.type.name === 'comment')
              if (commentMark) {
                decorations.push(
                  Decoration.inline(pos, pos + node.nodeSize, {
                    class: 'tiptap-comment',
                    'data-comment-id': commentMark.attrs.id
                  })
                )
                decorations.push(
                  Decoration.widget(pos + node.nodeSize, createCommentWidget(commentMark.attrs.number))
                )
              }
            })

            return DecorationSet.create(doc, decorations)
          }
        }
      })
    ]
  }
})

function createCommentWidget(number) {
  const span = document.createElement('span')
  span.textContent = number
  span.className = 'comment-number'
  return span
}
