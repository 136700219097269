<script setup>
import { ReferenceIcon } from '@/components/sources'
import { Skeleton } from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'
import { defineProps, toRefs, h, ref, inject, watch } from 'vue'
import { marked } from 'marked';
import { usePosthog } from '@/composables/analytics';
import { useRepo } from 'pinia-orm'
import { References } from '@/models/References'

const { identifyUser } = usePosthog();
const posthog = inject('posthog');
const referencesRepo = useRepo(References)

const props = defineProps(['show', 'answer', 'sources', 'runId', 'status'])
const { show, answer, sources, runId, status } = toRefs(props)

const isCopying = ref(false)
const copySuccess = ref(false)

const referencesFull = ref({})
function replacementsFor(answerText) {
    let replacements = {}
    if (!answerText || !runId.value) return replacements
    const matches = answerText.matchAll(/\[(.+?)\]/g)
    for (let match of matches) {
        const fullMatch = match[0]
        const innerContent = match[1]
        let [type, id] = innerContent.split('#', 2)
        type = type.toLowerCase()

        const i = sources.value[type]?.findIndex(s => s.metadata.parent_id === id)
        if (i >= 0) {
            const src = sources.value[type][i]
            if (!referencesFull.value['all']) referencesFull.value['all'] = [];
            if (!referencesFull.value['all'].includes(id)) {
                referencesFull.value['all'].push(id)
                const referenceData = {
                    run_id: runId.value,
                    source_id: src.id,
                    source_type: type,
                    order: referencesFull.value['all'].indexOf(id) + 1,
                }
                referencesRepo.save(referenceData)
            }
            replacements[fullMatch] = h(ReferenceIcon, { i: referencesFull.value['all'].indexOf(id), src: src })
        }
    }
    return replacements
}

watch(answer, () => {
    if (answer.value) {
        replacementsFor(answer.value)
    }
}, { immediate: true })


function parseWithComponents(inputString, replacements) {
    const tokens = marked.lexer(inputString)

    function processTextWithReplacements(text) {
        const parts = text.split(/(\[.+?\])/)
        return parts.map(part => replacements[part] || part)
    }
    
    function processInlineElements(tokens) {
        return tokens.flatMap(token => {

            if (token.type === 'text') {
                return processTextWithReplacements(token.raw)
            } else if (token.type === 'strong') {
                return h('strong', { class: 'font-semibold' }, processInlineElements(token.tokens))
            } else if (token.type === 'em') {
                return h('em', { class: 'italic' }, processInlineElements(token.tokens))
            } else if (token.type === 'codespan') {
                return h('code', {}, token.raw)
            } else if (token.type === 'link') {
                return h('a', { href: token.href}, processInlineElements(token.tokens))
            
            } else if (token.type === 'space') {
                return '\n'
            } else if (token.type === 'br') {
                return '\n'
            } else {
                // For any other inline elements, process them as text
                return processTextWithReplacements(marked.parser([token]))
            }
        })
    }

    function processToken(token) {
        if (token.type === 'paragraph') {
            return h('p', {class: 'text-p-answer'}, processInlineElements(token.tokens))
        } else if (token.type === 'strong') {
            return processInlineElements(token.tokens)
        } else if (token.type === 'em') {
            return h('em', { class: 'italic' }, processInlineElements(token.tokens))
        } else if (token.type === 'heading') {
            return h(`h${token.depth}`, { class: `text-${token.depth}-answer` }, processInlineElements(token.tokens))
        } else if (token.type === 'space') {
            return '\n'
        } else if (token.type === 'br') {
            return '\n'
        } else if (token.type === 'code') {
            return h('pre', {}, [h('code', {}, token.raw)])
        } else if (token.type === 'list') {
            const listItems = token.items.map((item, index) => processListItem({ ...item, index: index + 1 }, token.ordered));
            return h(token.ordered ? 'ol' : 'ul', { class: 'list-none pl-0' }, listItems);
        } else if (token.type === 'list_item') {
            return token.tokens.flatMap(processToken);
        } else if (token.type === 'table') {
            return processTable(token);
        } else {
            // For other block-level elements, we'll render as HTML and then process for replacements
            const renderedHtml = marked.parser([token])
            const div = document.createElement('div')
            div.innerHTML = renderedHtml
            return processTextWithReplacements(div.textContent)
        }
    }

    function processListItem(item, isOrdered) {
        const processedContent = processToken(item);
        
        function getBulletPoint(index) {
            return isOrdered 
                ? h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, `${index}.`)
                : h('span', { class: 'inline-block w-5 items-center text-center mr-0' }, '•');
        }

        // Helper function to safely process content
        function safeProcessInlineElements(content) {
            if (Array.isArray(content)) {
                return content.map(item => {
                    if (typeof item === 'string') {
                        return processInlineElements([{ type: 'text', raw: item }]);
                    }
                    return item;
                }).flat();
            } else if (typeof content === 'string') {
                return processInlineElements([{ type: 'text', raw: content }]);
            } else if (content && typeof content === 'object') {
                return [content]; // Assume it's already a VNode
            }
            return [];
        }

        // Check if the processed content contains nested lists
        const hasNestedList = Array.isArray(processedContent) && processedContent.some(el => el.type === 'ul' || el.type === 'ol');

        if (hasNestedList) {
            // For items with nested lists, we need to separate the text content from the nested list
            const textContent = [];
            const nestedLists = [];
            processedContent.forEach(el => {
                if (typeof el === 'string' || (el.type !== 'ul' && el.type !== 'ol')) {
                    textContent.push(el);
                } else {
                    nestedLists.push(el);
                }
            });

            return h('li', { class: 'flex mb-2 flex-col' }, [
                h('div', { class: 'flex' }, [
                    h('span', { class: 'text-muted-foreground mr-1' }, [getBulletPoint(item.index)]),
                    h('span', { class: 'text-list-answer' }, safeProcessInlineElements(textContent))
                ]),
                ...nestedLists.map(nestedList => h('div', { class: 'ml-4' }, [nestedList])) // Indent nested lists
            ]);
        } else {
            // Process the entire content safely
            const processedText = safeProcessInlineElements(processedContent);

            return h('li', { class: 'flex mb-2' }, [
                h('span', { class: 'text-muted-foreground mr-1' }, [getBulletPoint(item.index)]),
                h('span', { class: 'text-list-answer' }, processedText)
            ]);
        }
    }

    function processTable(token) {
        const header = token.header.map(cell => h('th', { class: 'px-4 py-2 border' }, processInlineElements(cell.tokens)));
        const body = token.rows.map(row => h('tr', {}, row.map(cell => h('td', { class: 'border px-4 py-2' }, cell.tokens.flatMap(processToken)))));
        return h('table', { class: 'table-auto mb-4 mt-4 text-sm rounded-xl' }, [
            h('thead', { class: 'font-semibold' }, h('tr', {class: 'bg-backgroundSecondary'}, header)),
            h('tbody', {}, body)
        ]);
    }

   
    return tokens.flatMap(processToken)
}

const render = () => {
    if (!answer.value) return null
    const replacements = replacementsFor(answer.value)
    return h('div', { class: "prose text-start" }, parseWithComponents(answer.value, replacements))
}

// New method to copy answer to clipboard
const copyAnswer = async () => {
    if (!answer.value) return

    isCopying.value = true
    copySuccess.value = false
    identifyUser()
    posthog.capture('$user_copied_answer', { run_id: runId.value })

    try {
        await navigator.clipboard.writeText(answer.value)
        copySuccess.value = true
        setTimeout(() => {
            copySuccess.value = false
        }, 1000)
    } catch (err) {
        console.error('Failed to copy: ', err)
    } finally {
        isCopying.value = false
    }
}
</script>

<template>
    <div>
        <div class="flex justify-between items-center mb-4">
            <h4 class="scroll-m-20 text-left text-xl font-semibold justify-start flex items-center">
                <svg class="mr-2" width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2 4.5C2 4.22386 2.22386 4 2.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H2.5C2.22386 5 2 4.77614 2 4.5ZM2 7.5C2 7.22386 2.22386 7 2.5 7H7.5C7.77614 7 8 7.22386 8 7.5C8 7.77614 7.77614 8 7.5 8H2.5C2.22386 8 2 7.77614 2 7.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H10.5C10.7761 10 11 10.2239 11 10.5C11 10.7761 10.7761 11 10.5 11H2.5C2.22386 11 2 10.7761 2 10.5Z"
                        fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
                Antwoord
            </h4>
        
        </div>
        <div class="ml-1" v-if="show">
            <component :is="render" />
             <!-- Copy Button -->
             <div v-if="status === 'success'" class="flex items-center justify-start space-x-2 mb-4 mt-2 flex-row">
                <Button 
                    @click="copyAnswer" 
                    class="focus:outline-none flex flex-row items-center bg-backgroundSecondary space-x-2"
                    :class="{'opacity-50 cursor-not-allowed': isCopying}"
                    :disabled="isCopying"
                    variant="outline"
                    size="sm"    
                >
                    
                    <div v-if="copySuccess">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                    </div>
                    <svg  v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"/><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/></svg>
                    <p class="text-xs">Kopieer antwoord</p>
                </Button>
            </div>
        </div>
        <div v-else>
            <Skeleton class="h-4 sm:h-5 my-1 sm:my-2 w-full sm:w-[500px] bg-backgroundSecondary border border-border rounded-full" />
            <Skeleton class="h-4 sm:h-5 my-1 sm:my-2 w-3/4 sm:w-[300px] bg-backgroundSecondary border border-border rounded-full" />
            <Skeleton class="h-4 sm:h-5 my-1 sm:my-2 w-5/6 sm:w-[400px] bg-backgroundSecondary border border-border rounded-full" />
        </div>
    </div>
</template>

<style>
.text-2-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.5rem;
    font-weight: 400;
}
.text-3-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.5rem;
    font-weight: 400;
}
.text-4-answer {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.6rem;
    font-weight: 400;
}

.text-4-answer .strong {
    font-family: 'Inter', sans-serif;
    font-size: 1rem!important;
    line-height: 2.5rem;
    font-weight: 400;
}

.text-p-answer {
    font-size: 0.98rem;
    padding-bottom: 0.50rem;
}

.strong {
    font-weight: 400;
}
</style>