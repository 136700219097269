<script setup>
import { ref, watch, inject, onMounted } from 'vue';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Support } from '@/components/sidebar/areas';
import { HelpCircle, ShieldCheck, Info } from 'lucide-vue-next';
import { Separator } from '@/components/ui/separator';
import { useTour } from '@/composables/tutorial';
import { useRouter } from 'vue-router';
import { usePosthog } from '@/composables/analytics';
import { useOnboardingStore } from '@/stores/onboarding';
const router = useRouter();
const { startTour, tours } = useTour();
const { identifyUser } = usePosthog();
const posthog = inject('posthog');

const showTutorialButton = ref(false);
const onboardingStore = useOnboardingStore();


watch(() => router.currentRoute.value.name, (newRouteName) => {
    showTutorialButton.value = tours[newRouteName] !== undefined;
}, { immediate: true });

const handleStartTour = () => {
    const routeName = router.currentRoute.value.name;
    startTour(routeName);
    identifyUser();
    posthog.capture('$user_started_tutorial', { for_route: routeName });
};

const handleFAQClick = () => {
    identifyUser();
    posthog.capture('$user_clicked_faq');
};

const handleSecurityClick = () => {
    identifyUser();
    posthog.capture('$user_clicked_security');
};


onMounted(async () => {
    if (!onboardingStore.is_onboarded) {
        const routeName = router.currentRoute.value.name;
        if (tours[routeName]) {
            await startTour(routeName);
        }
    }
});

</script>

<template>
    <Popover>
        <PopoverTrigger class="w-full" as-child>
            <slot />
        </PopoverTrigger>
        <PopoverContent class="w-[--radix-popover-trigger-width] min-w-56 rounded-lg">
            <div class="flex flex-col space-y-2">
                <div class="flex flex-row space-x-2 items-center justify-start">
                    <h2 class="text-left font-semibold space-x-2 px-3 w-full">
                        Helpdesk
                    </h2>
                </div>
                <Separator class="my-2" />
                <div class="flex flex-row space-x-2 w-full items-center justify-start">
                    <Support />
                </div>
                <div v-if="showTutorialButton" class="flex flex-row space-x-2 items-center justify-start">
                    <Button @click="handleStartTour" variant="ghost" size="sm"
                        class="w-full justify-start text-muted-foreground border border-transparent hover:border-border space-x-2 px-3">
                        <Info class="h-4 w-4" />
                        <h2 class="text-left tracking-tight">
                            Start tutorial
                        </h2>
                    </Button>
                </div>
                <div class="flex flex-row space-x-2 items-center justify-start">
                    <a href="https://zeno.law/faq" target="_blank" class="w-full" @click="handleFAQClick">
                        <Button variant="ghost" size="sm"
                            class="w-full justify-start text-muted-foreground border border-transparent hover:border-border space-x-2 px-3">
                            <HelpCircle class="h-4 w-4" />
                            <h2 class="text-left tracking-tight">
                                FAQ
                            </h2>
                        </Button>
                    </a>
                </div>
                <div class="flex flex-row space-x-2 items-center justify-start">
                    <a href="https://zeno.law/security" target="_blank" class="w-full" @click="handleSecurityClick">
                        <Button variant="ghost" size="sm"
                            class="w-full justify-start text-muted-foreground border border-transparent hover:border-border space-x-2 px-3">
                            <ShieldCheck class="h-4 w-4" />
                            <h2 class="text-left tracking-tight">
                                Security
                            </h2>
                        </Button>
                    </a>
                </div>
            </div>
        </PopoverContent>
    </Popover>
</template>
