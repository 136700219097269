<script setup>
import { Separator } from '@/components/ui/separator';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const route = useRoute();
const breadcrumbs = computed(() => {
  return route.meta.breadcrumbs || [];
});
</script>

<template>
  <nav class="fixed flex items-center p-4 w-full space-x-2 border-b border-border bg-background z-10">
    <div class="flex flex-row items-center space-x-3 pr-1">
      <slot />
      <Separator orientation="vertical" class="h-4" />
    </div>
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink v-if="breadcrumbs.length > 0" as-child>
            <router-link to="/">
              Home
            </router-link>
          </BreadcrumbLink>
          <BreadcrumbPage v-else>
            Home
          </BreadcrumbPage>
        </BreadcrumbItem>
        <template v-for="(crumb, index) in breadcrumbs" :key="crumb.path">
          <BreadcrumbSeparator class="[&>svg]:size-3" />
          <BreadcrumbItem>
            <BreadcrumbLink v-if="index < breadcrumbs.length - 1" as-child>
              <router-link :to="crumb.path">
                {{ crumb.label }}
              </router-link>
            </BreadcrumbLink>
            <BreadcrumbPage v-else>
              {{ crumb.label }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </template>
      </BreadcrumbList>
    </Breadcrumb>
  </nav>
</template>
