<script setup>
import { defineProps, toRefs, computed } from 'vue';
import { Answer } from '@/components/run'

const props = defineProps(['params'])
const { params } = toRefs(props)

const cell = computed(() => params.value.value)
</script>

<template>
    <div
        class="text-sm text-left break-words whitespace-normal flex flex-grow items-center space-x-2 h-full py-2 w-full align-middle my-auto">
        <span v-if="!cell?.answer" class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium" :class="{
            'animate-pulse bg-blue-100 text-blue-800': cell?.status === 'running',
            'bg-muted text-muted-foreground': cell?.status === 'pending',
            'bg-green-100 text-green-800': cell?.status === 'success',
            'bg-red-100 text-red-800': cell?.status === 'error'
        }">
            {{ (cell?.status ? cell.status.charAt(0).toUpperCase() + cell.status.slice(1) : '') }}
        </span>
        <Answer v-else :answer="cell?.answer" :showCopy="false" :sources="cell?.sources" :runId="cell?.id" />
    </div>
</template>