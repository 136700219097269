<script setup>
import { defineProps, toRefs, computed, ref } from 'vue'
import { Editor } from '@/components/document/editor'
import { Button } from '@/components/ui/button'
import { useRoute } from 'vue-router'
import { Review } from '@/models';  // Add Document to imports
import { useAxiosRepo } from '@pinia-orm/axios';
import { Loading } from '@/components'
import { useRouter } from 'vue-router'
import { api } from '@/plugins/api'
import { Loader2 } from 'lucide-vue-next'
import { Edit } from 'lucide-vue-next'
import { Input } from '@/components/ui/input'
import { Document } from '@/models'
const DocumentRepo = useAxiosRepo(Document)

const router = useRouter()

const props = defineProps(['document', 'isLoading'])
const { document, isLoading } = toRefs(props)
const route = useRoute()

const isDocumentPending = computed(() => {
    return document.value?.upload_status === 'pending'
})

const isDocumentDeleted = computed(() => {
    return !DocumentRepo.find(document.value?.id)
})

const review = computed(() => useAxiosRepo(Review).find(route.params.id))

async function startReview() {
    const review = await useAxiosRepo(Review).setAxios(api).api().post('/reviews/', { name: `Review ${document.value.title}`, document_ids: [document.value.id] })
    router.push({ name: 'review', params: { id: review.id } })
}

const currentEditingReview = ref(null)

async function updateReviewName() {
    await useAxiosRepo(Review).setAxios(api).api().put(`/reviews/${review.value.id}`, review.value)
    currentEditingReview.value = null
}
</script>

<template>
    <div v-if="!isLoading" class="h-[calc(100vh-55px)] bg-backgroundSecondary flex flex-col w-full">
        <div
            class="w-full mx-auto z-10 py-4 bg-background flex items-center  border-b border-border flex-shrink-0 h-16">
            <div class="flex items-center max-w-[90%] 2xl:max-w-[75%] mx-auto w-full">
                <Button @click="$router.back()" size="sm" variant="ghost" class="shrink-0">
                    <i class='bx bx-arrow-back mr-2'></i>
                    Terug
                </Button>
                <div class="flex-1 flex justify-center items-center">
                    <template v-if="currentEditingReview">
                        <Input 
                            v-model="review.name"
                            @keydown.enter="updateReviewName"
                            @blur="updateReviewName"
                            autofocus
                            class="text-primary font-bold text-lg  max-w-[80%] text-center"
                        />
                    </template>
                    <template v-else>
                        <h1 class="text-lg font-black text-primary truncate">{{ review?.name }}</h1>
                    </template>
                </div>
                <div v-if="route.name !== 'review'" class="shrink-0" :class="{ 'w-[89px]': route.name === 'review' }">
                    <Button @click="startReview" size="sm" variant="ghost" class="gradient-border bg-muted">
                        <p class="text-sm">Start review</p>
                    </Button>
                    
                </div>
                <div v-if="route.name === 'review'" class="shrink-0">
                    <Button @click="currentEditingReview = review" size="sm" variant="ghost">
                        <Edit class="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </div>
        <div class="flex-grow overflow-hidden">
            <template v-if="isDocumentPending">
                <div class="h-full flex items-center justify-center flex-col space-y-4">
                    <Loader2 class="h-8 w-8 animate-spin text-primary" />
                    <p class="text-muted-foreground">Document wordt verwerkt...</p>
                    <p class="text-sm text-muted-foreground/70">Dit kan enkele momenten duren</p>
                </div>
            </template>
            <template v-else-if="isDocumentDeleted">
                <div class="h-full flex items-center justify-center flex-col space-y-4">
                    <p class="text-muted-foreground italic">Document is verwijderd</p>
                </div>
            </template>
            <template v-else>
                <Editor :document="document" />
            </template>
        </div>
    </div>
    <Loading v-else />
</template>
