function getSourceLink(s) {
    if (s.metadata.document_type === 'consolidations') {
        return `https://wetten.overheid.nl/jci1.3:c:${s.metadata.parent_id}`
    } else if (s.metadata.document_type === 'cases') {
        return `https://uitspraken.rechtspraak.nl/inziendocument?id=${s.metadata.parent_id}`
    }
}

function getSourceIcon(s) {
    let icon = 'https://s2.googleusercontent.com/s2/favicons?domain='
    if (s.metadata.document_type === 'consolidations') {
        icon += 'www.overheid.nl'
    } else if (s.metadata.document_type === 'cases') {
        icon += 'www.rechtspraak.nl'
    }
    return icon
}

function getSourceTitle(s) {
    if (s.metadata.document_type === 'consolidations') {
        
        if (!s.metadata.title) {
            return 'Untitled';
        }
        return s.metadata.regulation_title || s.metadata.regulation.title
    } else if (s.metadata.document_type === 'cases') {
        return s.metadata.parent_id
    }
}

function getSourceId(s) {
    if (s.metadata.document_type === 'consolidations') {
        if (!s.metadata.title) {
            return 'Untitled';
        }
        return s.metadata.regulation_title || s.metadata.regulation.title
    } else if (s.metadata.document_type === 'cases') {
        return s.metadata.parent_id
    }
}

function getSourceSubTitle(s) {
    if (s.metadata.document_type === 'consolidations') {
        return s.metadata.local_title ? s.metadata.local_title : 'Onbekend'
    } else if (s.metadata.document_type === 'cases') {
        return s.metadata.body_of_government ? 
            s.metadata.body_of_government :
            s.metadata.parent_id
    }
}

function getSourceSearchId(s) {
    if (s.metadata.document_type === 'consolidations') {
        
        if (!s.metadata.title) {
            return 'Untitled';
        }
        const parts = s.metadata.title.split(',', 2);
        if (parts.length === 2) {
            return `${parts[1].trim()}: ${parts[0].trim()}`;
        } else {
            return s.metadata.title;
        }
    } else if (s.metadata.document_type === 'cases') {
        return s.metadata.parent_id
    }
}

function getSourceContent(s) {
    if (s.metadata.document_type === 'consolidations') {
        return s.page_content
    } else if (s.metadata.document_type === 'cases') {
        return s.page_content
    }
}

function getSourceType(s) {
    if (s.metadata.document_type === 'consolidations') {
        return 'consolidation'
    } else if (s.metadata.document_type === 'cases') {
        return 'case'
    }
}

export {
    getSourceLink,
    getSourceIcon,
    getSourceSubTitle,
    getSourceSearchId,
    getSourceTitle,
    getSourceType,
    getSourceContent,
    getSourceId
}