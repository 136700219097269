<script setup>
import { Sidebar, Navbar, Loading } from '@/components';
import { RouterView } from 'vue-router';
import { Toaster } from '@/components/ui/sonner';
import { useAuth } from '@/auth';
import { useData } from '@/composables';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { SidebarProvider, SidebarInset, SidebarTrigger } from '@/components/ui/sidebar';
//import MaintenancePage from '@/views/MaintenancePage.vue';
const auth = useAuth();
const { isLoading } = useData();
</script>
<template>
  <!-- <MaintenancePage v-if="isUnderMaintenance" /> -->
  <section v-if="auth.isAuthenticated && !auth.isLoading && !isLoading">
    <SidebarProvider>
      <Sidebar />
      <SidebarInset>
        <div class="flex flex-col flex-grow grid grid-cols-6">
          <Navbar>
            <SidebarTrigger class="flex-shrink-0 w-5 h-5 text-muted-foreground hover:bg-transparent hover:border-transparent hover:text-primary" />
          </Navbar>
          <RouterView class="h-[calc(100vh-55px)] col-span-6 mt-[55px]" />
        </div>
      </SidebarInset>
    </SidebarProvider>
    <Toaster />
    <ConfirmDialog />
  </section>
  <Loading v-else />
</template>

<style>
@import 'css/tailwind/tailwind.min.css';
@import 'main.css';

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: hsl(var(--primary));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Satoshi', sans-serif;
  color: hsl(var(--primary));
}

p,
span {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
</style>