
import { ref, onMounted } from 'vue';
import { Thread, Review, Sheet, Organization, User, Folder, Document, Membership } from '@/models';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';

export function useData() {
    const isLoading = ref(true)
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const reviewRepo = useAxiosRepo(Review).setAxios(api)
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api)
    const organizationRepo = useAxiosRepo(Organization).setAxios(api)
    const userRepo = useAxiosRepo(User).setAxios(api)
    const folderRepo = useAxiosRepo(Folder).setAxios(api)
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const membershipRepo = useAxiosRepo(Membership).setAxios(api)

    onMounted(async () => {
        const auth = useAuth();
        const { isAuthenticated } = storeToRefs(auth);
        if (!isAuthenticated.value) {
            await new Promise(resolve => {
                const checkAuthentication = () => {
                    if (isAuthenticated.value) {
                        resolve();
                    } else {
                        setTimeout(checkAuthentication, 100);
                    }
                };
                checkAuthentication();
            });
        }
        await Promise.all([
            threadRepo.api().get('/threads/'),
            reviewRepo.api().get('/reviews/'),
            sheetRepo.api().get('/sheets/'),
            organizationRepo.api().get('/organizations/'),
            userRepo.api().get('/users/'),
            folderRepo.api().get('/folders/'),
            documentRepo.api().get('/documents/'),
            membershipRepo.api().get('/organizations/memberships')
        ])
        isLoading.value = false;
    })

    return {
        isLoading
    }
}