<script setup>
import { ResizableHandle, ResizablePanelGroup, ResizablePanel } from '@/components/ui/resizable'
import { Document } from '@/components/document'
import SidePanel from '@/components/sidepanel/SidePanel.vue'
import { useReview } from '@/composables'
import { Loading } from '@/components'
const { review, isLoading } = useReview()
</script>

<template>
  <div v-if="!isLoading && review" class="h-[calc(100vh-55px)] bg-backgroundSecondary">
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel :defaultSize="70" :minSize="0">
        <Document :document="review?.documents[0]" :comments="review?.comments" />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel :defaultSize="30" :minSize="0" :maxSize="60">

        <SidePanel ctx="review" :id="review?.id" :runs="review?.runs" :comments="review?.comments" />
      </ResizablePanel>
    </ResizablePanelGroup>
  </div>
  <Loading v-else />
</template>
