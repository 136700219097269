<script setup>
import { useRouter } from 'vue-router';
import { Thread } from '@/models';
import { ResourceList } from '@/components/ResourceList';
import { BookMarked, FileSearch2 } from 'lucide-vue-next';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';

const router = useRouter();
const repo = useAxiosRepo(Thread).setAxios(api);
</script>

<template>
    <div>
        <ResourceList title="Research" :model="Thread" :headerIcon="BookMarked" :listIcon="FileSearch2"
            @new="router.push({ name: 'home' })" @click="router.push({ name: 'thread', params: { id: $event } })"
            @update="repo.api().put(`/threads/${$event.id}`, $event)"
            @delete="repo.api().delete(`/threads/${$event}`, { delete: $event })"
            searchPlaceholder="Zoek in onderzoek..." buttonText="Nieuw onderzoek"
            emptyPlaceholderHeader="Geen onderzoek gevonden"
            emptyPlaceholderParagraph="Je hebt nog geen onderzoek gemaakt." />
    </div>
</template>
