<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { SelectDocumentDialog } from '@/components/documents';
import { Review } from '@/models';
import { ResourceList } from '@/components/ResourceList';
import { TextQuote, FileCheck } from 'lucide-vue-next';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';

const router = useRouter();
const repo = useAxiosRepo(Review).setAxios(api);
const dialogOpen = ref(false);
const confirmSelection = async (selectedItems) => {
    const response = await repo.api().post('/reviews/', { document_ids: selectedItems.map(item => item.id) })
    const review = response.entities[0]
    router.push({ name: 'review', params: { id: review.id } });
};
</script>

<template>
    <div>
        <ResourceList title="Reviews" :model="Review" :headerIcon="TextQuote" :listIcon="FileCheck"
            @new="dialogOpen = true" @click="router.push({ name: 'review', params: { id: $event } })"
            @update="repo.api().put(`/reviews/${$event.id}`, $event)"
            @delete="repo.api().delete(`/reviews/${$event}`, { delete: $event })" searchPlaceholder="Zoek in reviews..."
            buttonText="Nieuwe review" emptyPlaceholderHeader="Geen reviews gevonden"
            emptyPlaceholderParagraph="Je hebt nog geen reviews gemaakt." />
        <SelectDocumentDialog v-model:isOpen="dialogOpen" :callback="confirmSelection" :single="true" />
    </div>
</template>
