<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { SelectDocumentDialog } from '@/components/documents';
import { Sheet } from '@/models';
import { ResourceList } from '@/components/ResourceList';
import { SheetIcon, FileSpreadsheetIcon } from 'lucide-vue-next';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
const router = useRouter();
const repo = useAxiosRepo(Sheet).setAxios(api);
const dialogOpen = ref(false);
const confirmSelection = async (selectedItems) => {
    const response = await repo.api().post('/sheets/', { document_ids: selectedItems.map(item => item.id) })
    const sheet = response.entities[0]
    router.push({ name: 'sheet', params: { id: sheet.id } });
};
</script>

<template>
    <div>
        <ResourceList title="Sheets" :model="Sheet" :headerIcon="SheetIcon" :listIcon="FileSpreadsheetIcon"
            @new="dialogOpen = true" @click="router.push({ name: 'sheet', params: { id: $event } })"
            @update="repo.api().put(`/sheets/${$event.id}`, $event)"
            @delete="repo.api().delete(`/sheets/${$event}`, { delete: $event })" searchPlaceholder="Zoek in sheets..."
            buttonText="Nieuwe sheet" emptyPlaceholderHeader="Geen sheets gevonden"
            emptyPlaceholderParagraph="Je hebt nog geen sheets gemaakt." />
        <SelectDocumentDialog v-model:isOpen="dialogOpen" :callback="confirmSelection" />
    </div>
</template>
