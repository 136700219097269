<script setup>
import { ref, inject, defineProps } from 'vue';
import { Button } from '@/components/ui/button';
import PersonalProfileSettings from '@/components/settings/PersonalProfileSettings.vue';
import OrganizationProfileSettings from '@/components/settings/OrganizationProfileSettings.vue';
import OrganizationMembersSettings from '@/components/settings/OrganizationMembersSettings.vue';
import SubscriptionSettings from '@/components/settings/SubscriptionSettings.vue';
import { AlertDialogContent, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import { User, CreditCard, LogOut, Building, Users } from 'lucide-vue-next';
import { ThemeToggle } from '@/components/settings';
import { useScope } from '@/composables/scope';
import { useAuth } from '@/auth';
const auth = useAuth();
const { user, organization, subscription } = useScope();


defineProps({
    asChild: {
        type: Boolean,
        default: false
    }
})

import { usePosthog } from '@/composables/analytics';
const { resetUser } = usePosthog();

const activeTab = ref('profile');

let settingsTabs = []
if (auth.organization) {
    settingsTabs = [
        { icon: User, id: 'profile', label: 'Profiel', component: PersonalProfileSettings },
        { icon: Building, id: 'organization', label: 'Organisatie', component: OrganizationProfileSettings },
        { icon: Users, id: 'members', label: 'Leden', component: OrganizationMembersSettings },
        { icon: CreditCard, id: 'subscription', label: 'Abonnement', component: SubscriptionSettings },
    ]
} else {
    settingsTabs = [
        { icon: User, id: 'profile', label: 'Profiel', component: PersonalProfileSettings },
        { icon: CreditCard, id: 'subscription', label: 'Abonnement', component: SubscriptionSettings },
    ]
}

const posthog = inject('posthog');
const logOut = () => {
    auth.logout();
    posthog.capture('$user_logged_out');
    resetUser();
}
</script>

<template>
    <Dialog>
        <DialogTrigger :asChild="asChild">
            <slot />
        </DialogTrigger>
        <AlertDialogContent class="p-0 min-h-[45vh] w-full">
            <AlertDialogCancel
                class="absolute cursor-pointer top-1 right-1 hover:bg-background hover:text-secondary border-none rounded-full">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                        fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
            </AlertDialogCancel>
            <div class="flex">
                <!-- Sidebar Navigation -->
                <aside class="border-r border-border">
                    <nav class="px-3 py-4 flex flex-col h-full">
                        <ul class="space-y-2 flex-grow pb-4">
                            <li v-for="tab in settingsTabs" :key="tab.id">
                                <Button variant="ghost" @click="activeTab = tab.id" :class="[
                                    'w-full text-sm justify-start px-2 border-border border-transparent rounded-lg',
                                    activeTab === tab.id
                                        ? 'bg-accent text-accent-foreground border border-border hover:border-border'
                                        : 'hover:bg-accent border border-transparent hover:border-border'
                                ]">
                                    <component :is="tab.icon" class="w-4 h-4 mr-2 text-primary" />
                                    {{ tab.label }}
                                </Button>
                            </li>
                        </ul>
                        <div class="mt-auto flex flex-col space-y-4">
                            <ThemeToggle />

                            <Button variant="outline" class="w-full justify-start px-2 bg-muted" @click="logOut">
                                <LogOut class="w-4 h-4 text-primary mr-2" />
                                <p class="text-sm">
                                    Uitloggen
                                </p>


                            </Button>
                        </div>
                    </nav>
                </aside>
                <!-- Content Area -->
                <div class="flex-1 min-h-[100px] p-4">
                    <div class="text-left" v-if="activeTab === 'profile'">
                        <h2 class="text-1xl font-bold mb-1">Profiel instellingen</h2>
                        <p class="text-sm text-muted-foreground mb-4">Bewerk hier je persoonlijke gegevens.</p>
                        <PersonalProfileSettings v-model="user" />
                    </div>
                    <div class="text-left" v-else-if="activeTab === 'organization' && organization">
                        <h2 class="text-1xl font-bold mb-1">Organisatie</h2>
                        <p class="text-sm text-muted-foreground mb-4">Bewerk hier de gegevens van je organisatie.</p>
                        <OrganizationProfileSettings v-model="organization" />
                    </div>
                    <div class="text-left" v-else-if="activeTab === 'members' && organization">
                        <h2 class="text-1xl font-bold mb-1">Leden</h2>
                        <p class="text-sm text-muted-foreground mb-4">Beheer hier de leden van je organisatie.</p>
                        <OrganizationMembersSettings v-model="organization.users" />
                    </div>
                    <div class="text-left" v-else-if="activeTab === 'subscription'">
                        <h2 class="text-1xl font-bold mb-1">Abonnement</h2>
                        <p class="text-sm text-muted-foreground mb-4">Bewerk hier je abonnement.</p>
                        <SubscriptionSettings v-model="subscription" />
                    </div>

                </div>
            </div>
        </AlertDialogContent>
    </Dialog>
</template>
