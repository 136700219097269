<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
    run: {
        type: Object,
        required: true
    }
})

const stages = computed(() => {
    // Find the current running step from plan steps
    if (props.run?.plan?.steps?.length) {
        const runningStep = props.run.plan.steps.find(step => step.status === 'running')
        if (runningStep) {
            return [{
                title: runningStep.step,
                status: 'running',
                animate: true
            }]
        }
    }

    // Show answer step if all plan steps are complete and run is still going
    if (!props.run?.plan?.steps?.some(p => p.status !== 'success') && props.run?.status === 'running') {
        return [{
            title: 'Antwoord formuleren',
            status: 'running',
            animate: true
        }]
    }

    // Show completed status
    if (props.run?.status === 'success') {
        return [{
            title: 'Voltooid',
            status: 'success',
            animate: false
        }]
    }

    return [] // Return empty if nothing is running
})
</script>

<template>
    <div class="flex flex-col space-y-2 w-full">
        <div v-for="(stage, i) in stages" :key="i" 
             class="flex items-start space-x-2 py-2 px-2 w-full">
            <div class="flex justify-center items-center flex-shrink-0">
                <i v-if="stage.status === 'running'" 
                   class="bx bx-analyse animate-spin-slow text-md text-primary"></i>
                <i v-else-if="stage.status === 'success'" 
                   class="bx bx-check text-md text-primary"></i>
                <span v-else 
                      class="text-xs font-medium text-primary">{{ i + 1 }}</span>
            </div>
            <p class="text-xs text-left w-full min-w-[100%] whitespace-normal break-normal overflow-hidden text-ellipsis"
               :class="{ 'animate-color-wave-bg': stage.animate, 'text-medium': stage.animate }">
                {{ stage.title }}
            </p>
        </div>
    </div>
</template>

<style scoped>
@keyframes colorWaveBg {
    0% {
        background-position: 200% center;
        opacity: 1;
    }
    100% {
        background-position: -200% center;
        opacity: 1;
    }
    25% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0.7;
    }
}

.animate-color-wave-bg {
    background-image: linear-gradient(to right,
            hsl(var(--primary)) 0%,
            hsl(var(--muted-foreground) / 0.9) 25%,
            hsl(var(--primary)) 50%,
            hsl(var(--muted-foreground) / 0.7) 75%,
            hsl(var(--primary)) 100%);
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: colorWaveBg 4s linear infinite;
}

.animate-spin-slow {
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}
</style> 