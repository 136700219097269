<script setup>
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useForm } from 'vee-validate';
import { defineModel } from 'vue';
import { toast } from 'vue-sonner';
import { AlertDialogAction } from '@/components/ui/alert-dialog';
import { api } from '@/plugins/api'
import { useAxiosRepo } from '@pinia-orm/axios'
import { Organization } from '@/models';
import { useScope } from '@/composables/scope';
const organization = defineModel();
const repo = useAxiosRepo(Organization).setAxios(api);
const { user } = useScope();

const { handleSubmit, resetForm } = useForm({
    initialValues: organization.value,
})

const onSubmit = handleSubmit(async (values) => {
    try {
        organization.value.name = values.name
        organization.value.tax_id = values.tax_id
        organization.value.email = values.email
        await repo.api().put(`/organizations/`, { name: values.name, tax_id: values.tax_id, email: values.email })
        toast.success('Je organisatie is opgeslagen');
    } catch (error) {
        toast.error('Er is iets misgegaan bij het opslaan van je organisatie');
    }
})
</script>

<template>
    <form class="flex flex-col h-full w-full space-y-2 text-left" @submit="onSubmit">
        <div class="col-span-2">
            <FormField v-slot="{ componentField }" name="name" class="col-span-1">
                <FormItem>
                    <FormLabel>Naam</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="organization.name" v-bind="componentField" />
                    </FormControl>
                    <FormDescription>
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="tax_id" class="col-span-1">
                <FormItem>
                    <FormLabel>BTW nummer</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="organization.tax_id" v-bind="componentField" />
                    </FormControl>
                    <FormDescription>
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="email" class="col-span-1">
                <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        
                        <Input v-if="user.role === 'admin' || user.role === 'owner'" type="text" :placeholder="organization.email" v-bind="componentField" class="w-full" />
                        <Input v-else type="text" :placeholder="organization.email" v-bind="componentField" class="w-full" disabled />
                    </FormControl>
                    <FormDescription>
                        Dit email adres wordt gebruikt voor communicatie over je organizatie en facturatie.
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>
        </div>
        <div class="flex flex-row space-x-2 pt-2 absolute bottom-4">
            <AlertDialogAction asChild>
                <Button type="submit">
                    Opslaan
                </Button>
            </AlertDialogAction>
            <Button type="button" variant="outline" @click="resetForm">
                Reset
            </Button>
        </div>
    </form>
</template>