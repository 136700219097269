// useThread.js
import { computed, onMounted, onUnmounted, watch, ref } from 'vue';
import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread, ModelRun } from '@/models';
import { api } from '@/plugins/api';
import { useSocket } from '@/stores/socket';
import { useRoute, useRouter } from 'vue-router';

export function useThread() {
    const route = useRoute();
    const router = useRouter();
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    const socket = useSocket();
    const isLoading = ref(true)

    const thread = computed(() => {
        return threadRepo.withAllRecursive().find(route.params.id);
    });

    async function leave() {
        if (!thread.value) return;
        await socket.emit('threads', 'leave_thread', { thread_id: thread.value.id })
    }

    async function enter() {
        isLoading.value = true
        await socket.connect('threads')
        if (!thread.value) {
            router.push({ name: 'home' })
            return;
        }
        if (!thread.value.runs?.length) {
            await runRepo.api().get(`/threads/${route.params.id}/runs`)
        }
        await socket.emit('threads', 'enter_thread', { thread_id: thread.value.id })
        isLoading.value = false
    }

    onMounted(enter)
    watch(route.params.id, async (oldId, newId) => {
        if (oldId === newId) return
        await enter()
    })
    onUnmounted(leave)

    return { thread, isLoading }
}