import { CastAttribute } from 'pinia-orm'

export class DateCast extends CastAttribute {
    get(value) {
        if (typeof value === 'string') {
            const date = new Date(value.replace('Z', ''))
            return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        }
        return value
    }

    set(value) {
        return value instanceof Date ? value : value
    }
}
