<script setup>
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible'
import { ChevronDown, ChevronUp, ChevronsDown, ChevronsUp } from 'lucide-vue-next'
import { SourceReference } from '@/components/sources'
import { Skeleton } from '@/components/ui/skeleton'
import { defineProps, ref, toRefs } from 'vue'

const props = defineProps(['icon', 'sources', 'show', 'status'])
const { icon, sources, show, status} = toRefs(props)
const isOpen = ref(false)

</script>

<template>
    <div>
        <Collapsible v-model:open="isOpen">
            <CollapsibleTrigger class="w-full sticky top-0 z-10" :disabled="status === 'running'">
                <div class="flex justify-between items-center border border-border ml-1 rounded-lg p-2 bg-backgroundSecondary hover:bg-accent cursor-pointer rounded-b-none">

                    <template v-if="status === 'running'">
                            <h4 class="text-md font-semibold items-center flex-row flex justify-between space-x-2">
                            <i class="animate-spin-slow bx bx-analyse text-primary mr-1"></i>
                            <span class="animate-color-wave-bg text-md">Referenties</span>
                            </h4>
                        </template>
                        <template v-else>
                            <h4 class="text-md font-semibold items-center flex-row flex justify-between space-x-2">
                            <component :is="icon" class="mr-1" :size="16" />
                            <span>Referenties</span>
                            </h4>
                            <div v-if="sources?.length > 5 && status !== 'running'" class="flex flex-row items-center">
                                <div v-if="!isOpen">
                                    <ChevronUp class="h-4 w-4 mr-1" />
                                </div>
                                <div v-else>
                                    <ChevronDown class="h-4 w-4 mr-1" />
                                </div>
                                <span class="sr-only">Toggle</span>
                            </div>
                        </template>
                </div>
            </CollapsibleTrigger>
            <div class="flex-grow rounded-lg overflow-hidden p-4 bg-background ml-1 border border-border border-t-0 rounded-t-none">
                <!-- FILTER BUTTON, SKIPPED FOR NOW -->
                <!---<div v-if="sources?.length > 5 && show && status !== 'running'" class="flex flex-row justify-between w-full pb-2 mb-2 border-b border-border">
                    <Button variant="outline" class="text-sm text-muted-foreground flex flex-row justify-between h-8 p-0 pr-2 pl-1 py-2">
                        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                        <p class="text-xs"> Datum </p>
                    </Button>
                </div> -->
                <div v-if="status !== 'running'" >
                    <p v-if="!sources?.length && status !== 'running'" class="text-left text-sm text-muted-foreground">
                        Geen referenties gevonden.
                    </p>
                    <div v-if="sources?.length" class="grid grid-cols-1 lg:grid-cols-1 gap-1 mb-1 pr-1">
                        <SourceReference v-for="(src, i) in sources.slice(0, 5)" :key="i" :src="src" :i="i" />
                    </div>
                    <CollapsibleContent v-if="sources?.length > 5">
                        <div class="grid grid-cols-1 lg:grid-cols-1 gap-1 mb-1 pr-1">
                            <SourceReference v-for="(src, i) in sources.slice(5)" :key="i" :src="src" :i="i + 5" />
                        </div>
                    </CollapsibleContent>
                </div>
                <CollapsibleTrigger v-if="sources?.length > 5 && show && status !== 'running'"
                        class="pl-1 mt-2 flex flex-row items-center justify-center w-full">
                        <div class="flex flex-row items-center justify-center w-full border border-border rounded-lg p-2 bg-backgroundSecondary hover:bg-accent cursor-pointer">
                        <div v-if="!isOpen">
                            <ChevronsDown class="h-4 w-4 mr-1" />
                            </div>
                        <div v-else>
                            <ChevronsUp class="h-4 w-4 mr-1" />
                        </div>
                        <span class="sr-only">Toggle</span>
                    </div>
                </CollapsibleTrigger>
                <div v-if="status === 'running'" class="grid grid-cols-1 lg:grid-cols-1 gap-1 mb-1 pr-1">
                    <Skeleton v-for="i in 4" :key="i" class="h-[75px] lg:h-[90px] w-full mx-1 bg-backgroundSecondary border border-border rounded-lg" />
                </div>
            </div>
        </Collapsible>
    </div>
</template>

<style scoped>
@keyframes colorWaveBg {

0% {
    background-position: 200% center;
    opacity: 1;
}

100% {
    background-position: -200% center;
    opacity: 1;
}

25% {
    opacity: 0.7;
}

50% {
    opacity: 1;
}

75% {
    opacity: 0.7;
}
}

.animate-color-wave-bg {
background-image: linear-gradient(to right,
        hsl(var(--primary)) 0%,
        hsl(var(--muted-foreground) / 0.9) 25%,
        hsl(var(--primary)) 50%,
        hsl(var(--muted-foreground) / 0.7) 75%,
        hsl(var(--primary)) 100%);
background-size: 200% auto;
color: transparent;
-webkit-background-clip: text;
background-clip: text;
animation: colorWaveBg 4s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

.animate-spin-slow {
    background-color: hsl(var(--primary));
    opacity: 1;
    animation: fadeInOut 1.5s ease-in-out infinite;
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: colorWaveBg 4s linear infinite, spin 1.5s ease-out infinite;
}
</style>