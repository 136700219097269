// useSheet.js
import { computed, onMounted, onUnmounted, watch, ref } from 'vue';
import { Sheet, SheetPrompt, SheetDocument, Document, Prompt, ModelRun } from '@/models';
import { useSocket } from '@/stores/socket';
import { useRoute } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { useRouter } from 'vue-router';
export function useSheet() {
    const router = useRouter();
    const route = useRoute();
    const repo = useAxiosRepo(Sheet).setAxios(api)
    const sheetPromptRepo = useAxiosRepo(SheetPrompt).setAxios(api)
    const sheetDocumentRepo = useAxiosRepo(SheetDocument).setAxios(api)
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const promptRepo = useAxiosRepo(Prompt).setAxios(api)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    const socket = useSocket();
    const isLoading = ref(true)

    const sheet = computed(() => {
        return repo.withAllRecursive().find(route.params.id);
    });

    async function addPrompt(content, index = null) {
        const text = content.trim()
        if (!text) return
        await socket.emit('sheets', 'add_prompt', { sheet_id: sheet.value.id, index: index, content: text })
    }

    async function updatePrompt(prompt, index = null) {
        if (!prompt.content) return
        await socket.emit('sheets', 'update_prompt', { sheet_id: sheet.value.id, prompt: prompt, index: index })
    }

    async function removePrompt(prompt_id) {
        if (!prompt_id) return
        await socket.emit('sheets', 'remove_prompt', { sheet_id: sheet.value.id, prompt_id: prompt_id })
        sheetPromptRepo.where('prompt_id', prompt_id).where('sheet_id', sheet.value.id).delete()
    }

    async function addDocument(index, document_id) {
        if (!document_id || !index) return
        await socket.emit('sheets', 'add_document', { sheet_id: sheet.value.id, index: index, document_id: document_id })
    }

    async function removeDocument(document_id) {
        if (!document_id) return
        await socket.emit('sheets', 'remove_document', { sheet_id: sheet.value.id, document_id: document_id })
        sheetDocumentRepo.where('document_id', document_id).where('sheet_id', sheet.value.id).delete()
    }

    async function startRun(status = 'incomplete', prompt_ids = null, document_ids = null) {
        await socket.emit('sheets', 'start_run', { sheet_id: sheet.value.id, status: status, prompt_ids: prompt_ids, document_ids: document_ids })
    }

    async function cancelRun(prompt_ids = null, document_ids = null) {
        await socket.emit('sheets', 'cancel_run', { sheet_id: sheet.value.id, prompt_ids: prompt_ids, document_ids: document_ids })
    }

    async function leave() {
        if (!sheet.value) return;
        await socket.emit('sheets', 'leave_sheet', { sheet_id: sheet.value.id })
    }

    async function enter() {
        isLoading.value = true
        await socket.connect('sheets')
        if (!sheet.value) {
            router.push({ name: 'home' })
            return;
        }
        await Promise.all([
            !sheet.value.documents?.length ? Promise.all([
                documentRepo.api().get(`/sheets/${route.params.id}/documents`),
                sheetDocumentRepo.api().get(`/sheets/${route.params.id}/sheet-documents`)
            ]) : Promise.resolve(),
            !sheet.value.prompts?.length ? Promise.all([
                promptRepo.api().get(`/sheets/${route.params.id}/prompts`),
                sheetPromptRepo.api().get(`/sheets/${route.params.id}/sheet-prompts`)
            ]) : Promise.resolve(),
            !sheet.value.runs?.length ? runRepo.api().get(`/sheets/${route.params.id}/runs`) : Promise.resolve()
        ])
        await socket.emit('sheets', 'enter_sheet', { sheet_id: sheet.value.id })
        isLoading.value = false
    }

    async function updateSheet(sheet) {
        await repo.api().put(`/sheets/${sheet.id}`, { name: sheet.name });
    }

    onMounted(enter)
    watch(route.params.id, async (oldId, newId) => {
        if (oldId === newId) return
        await enter()
    })
    onUnmounted(leave)

    return { sheet, isLoading, addPrompt, updatePrompt, removePrompt, addDocument, removeDocument, startRun, cancelRun, updateSheet }
}
