<script setup>
import { ref, defineEmits, defineProps } from 'vue';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Card } from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
const isOpen = ref(false);

const emit = defineEmits(['update:areaFilters'])
const props = defineProps({
  defaultFilters: {
    type: Array,
    default: () => ['Alles']
  }
})
const selectedAreaFilters = ref(props.defaultFilters);

const handlePopoverUpdate = (open) => {
  isOpen.value = open;
};

const areaOptions = [
  {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-globe"><circle cx="12" cy="12" r="10"/><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/><path d="M2 12h20"/></svg>',
    title: 'Alles',
    description: 'Doorzoek alle rechtsgebieden'
  },
  {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>',
    title: 'Civiel recht',
    description: 'Doorzoek bronnen binnen het civiel recht'
  },
  {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-target"><circle cx="12" cy="12" r="10"/><circle cx="12" cy="12" r="6"/><circle cx="12" cy="12" r="2"/></svg>',
    title: 'Strafrecht',
    description: 'Doorzoek bronnen binnen het strafrecht'
  },
  {
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-landmark"><line x1="3" x2="21" y1="22" y2="22"/><line x1="6" x2="6" y1="18" y2="11"/><line x1="10" x2="10" y1="18" y2="11"/><line x1="14" x2="14" y1="18" y2="11"/><line x1="18" x2="18" y1="18" y2="11"/><polygon points="12 2 20 7 4 7"/></svg>',
    title: 'Bestuursrecht',
    description: 'Doorzoek bronnen binnen het bestuursrecht'
  },
];

const toggleFilter = (item) => {
  if (item.title === 'Alles') {
    selectedAreaFilters.value = ['Alles'];
  } else {
    const index = selectedAreaFilters.value.indexOf(item.title);
    if (index === -1) {
      selectedAreaFilters.value = selectedAreaFilters.value.filter(f => f !== 'Alles');
      selectedAreaFilters.value.push(item.title);
    } else {
      selectedAreaFilters.value.splice(index, 1);
    }

    if (selectedAreaFilters.value.length === 0) {
      selectedAreaFilters.value = ['Alles'];
    }
  }

  // Emit the updated filters
  emit('update:areaFilters', selectedAreaFilters.value);
};

//const getButtonText = () => {
//return selectedAreaFilters.value.includes('Alles') ? 'Rechtsgebied' : selectedAreaFilters.value.join(', ');
//};
</script>

<template>
  <Popover @update:open="handlePopoverUpdate">
    <PopoverTrigger>
      <TooltipProvider :delayDuration="50">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="sm" :class="[
              'relative flex items-center justify-center hover:bg-background hover:border-transparent',
              isOpen ? ' bg-background' : 'text-muted-foreground',
            ]">
              <i class='bx bx-filter-alt text-primary text-lg'
                :class="selectedAreaFilters.includes('Alles') ? 'text-muted-foreground' : 'text-gradient'"></i>
              <span v-if="!selectedAreaFilters.includes('Alles')"
                class="absolute -top-1 -right-1 bg-gradient text-background rounded-full w-4 h-4 text-xs flex items-center justify-center">
                {{ selectedAreaFilters.length }}
              </span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Filter rechtsgebieden</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </PopoverTrigger>
    <PopoverContent class="w-[350px] bg-backgroundSecondary">
      <div class="space-y-2">
        <div class="grid grid-cols-2 gap-2">
          <Card v-for="item in areaOptions" :key="item.title" :class="[
            'cursor-pointer p-2 h-20 flex flex-col justify-start',
            selectedAreaFilters.includes(item.title) ? 'bg-accent text-accent-foreground' : ''
          ]" @click="toggleFilter(item)">
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-2">
                <span class="text-lg" v-html="item.icon"></span>
                <h5 class="font-medium">{{ item.title }}</h5>
              </div>
              <svg v-if="selectedAreaFilters.includes(item.title)" xmlns="http://www.w3.org/2000/svg" width="20"
                height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7"
                stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check text-secondary">
                <path d="M20 6 9 17l-5-5" />
              </svg>
            </div>
            <p class="text-xs mt-2 text-muted-foreground">{{ item.description }}</p>
          </Card>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>
