<script setup>

import { FolderPlusIcon, Trash2Icon, Vault } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { DmsTable } from '@/components/documents';
import { ref, watch, computed } from 'vue';
import { useDocumentStore } from '@/stores/';
import { useConfirm } from '@/composables/useConfirm';
import UploadMultiple from '@/components/documents/UploadMultiple.vue';
import { Input } from '@/components/ui/input';
import { toast } from 'vue-sonner';
import {
    Breadcrumb,
    BreadcrumbList,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@/components/ui/breadcrumb';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from '@/components/ui/dialog';
import { storeToRefs } from 'pinia';
import FeatureHeader from '@/components/FeatureHeader.vue';
import { useFileUpload } from '@/composables/upload';
import { ActionCard } from '@/components/documents';
import { ChevronRight } from 'lucide-vue-next';
import { Checkbox } from '@/components/ui/checkbox';
import { FolderIcon } from 'lucide-vue-next';

const dms = useDocumentStore();
const { open: confirm } = useConfirm();
const selectedItems = ref([]);
const newFolderName = ref('');
const showCreateFolderDialog = ref(false);
const itemsToCreateFolder = ref([]);
const showAddToFolderDialog = ref(false);
const selectedFolderId = ref(null);

// Use storeToRefs for reactive store properties
const { items, breadcrumbs, isLoading } = storeToRefs(dms);
const {
    isUploading,
    uploadProgress,
    filesLength,
    isFolderUpload,
    isDragging,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop
} = useFileUpload({
    onUploadComplete: () => {
        toast.success('Upload voltooid');
    },
    onUploadError: () => {
        toast.error('Upload mislukt');
    },
    onUploadProgress: (progress) => {
        uploadProgress.value = progress;
    },
    onFilesLengthChange: (length) => {
        filesLength.value = length;
    }
});

const handleSelectionChange = (selection) => {
    selectedItems.value = selection;
};

const handleCreateFolder = () => {
    showCreateFolderDialog.value = true;
    itemsToCreateFolder.value = selectedItems.value.map(id => items.value.find(item => item.id === id)).filter(Boolean);
    newFolderName.value = itemsToCreateFolder.value.length > 0 
        ? `Nieuwe map met ${itemsToCreateFolder.value.length} item${itemsToCreateFolder.value.length > 1 ? 's' : ''}` 
        : 'Nieuwe map';
};

const submitCreateFolder = async () => {
    const folderName = newFolderName.value.trim();
    if (!folderName) {
        toast.error('Vul een naam in voor de nieuwe map');
        return;
    }
    try {
        closeDialog();
        await dms.createFolder(folderName, itemsToCreateFolder.value);
        selectedItems.value = [];
        itemsToCreateFolder.value = [];
    } catch (error) {
        toast.error('Er is iets misgegaan bij het aanmaken van de map');
    }
};

const handleFolderNameChange = async (folderId, newName) => {
    await dms.updateFolderName(folderId, newName);
};


const handleContextMenuDelete = async (items) => {
    const itemsToDelete = items.map(id => dms.items.find(item => item.id === id)).filter(Boolean);
    const itemCount = itemsToDelete.length;
    const itemType = itemCount === 1 ? (itemsToDelete[0].isFolder ? 'map' : 'document') : 'items';

    const isConfirmed = await confirm(`Weet je zeker dat je ${itemCount > 1 ? 'deze ' + itemCount + ' ' : 'dit '}${itemType} wilt verwijderen?`);
    
    if (isConfirmed) {
        await dms.deleteItems(itemsToDelete);
    }
};

const handleContextMenuCreateFolder = async (items) => {
    showCreateFolderDialog.value = true;
    itemsToCreateFolder.value = items.map(id => dms.items.find(item => item.id === id)).filter(Boolean);
    newFolderName.value = `Nieuwe map met ${itemsToCreateFolder.value.length} item${itemsToCreateFolder.value.length > 1 ? 's' : ''}`;
};

const closeDialog = () => {
    showCreateFolderDialog.value = false;
    newFolderName.value = '';
};

watch(showCreateFolderDialog, (newValue) => {
    if (!newValue) {
        newFolderName.value = '';
    }
});

const handleFolderDoubleClick = async (folderId) => {
    selectedItems.value = [];
    await dms.toFolder(folderId);
};

// Add this computed property
const selectedItemsCount = computed(() => selectedItems.value.length);

const itemsToAddToFolder = ref([]);
const handleAddToFolder = async () => {
    itemsToAddToFolder.value = selectedItems.value.map(id => dms.items.find(item => item.id === id)).filter(Boolean);
    showAddToFolderDialog.value = true;
};

const closeAddToFolderDialog = () => {
    showAddToFolderDialog.value = false;
    selectedFolderId.value = null;
};

const submitAddToFolder = async () => {
    if (!selectedFolderId.value) {
        toast.error('Selecteer een map');
        return;
    }
    
    try {
        await dms.moveItemsToFolder(itemsToAddToFolder.value, selectedFolderId.value);
        closeAddToFolderDialog();
        itemsToAddToFolder.value = [];
        selectedItems.value = [];

        toast.success('Items succesvol verplaatst');
    } catch (error) {
        toast.error('Er is iets misgegaan bij het verplaatsen van de items');
    }
};

</script>

<template>
    <div class="h-full flex flex-col">
        <FeatureHeader title="Vault" :showButton="false" :icon="Vault" :marginBottom="false"/>
        <div class="py-4 px-6 flex items-center border-border border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <Breadcrumb>
                <BreadcrumbList class="flex items-center space-x-3">
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            class="flex items-center text-base transition-colors hover:text-secondary cursor-pointer"
                            @click="dms.toFolder(null)">
                            <h1 class="text-lg font-bold hover:text-secondary">Alle documenten</h1>
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <template v-for="(folder, index) in breadcrumbs" :key="folder.id">
                        <ChevronRight class="h-4 w-4 text-muted-foreground" />
                        
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                :class="[
                                    'flex items-center text-base transition-colors',
                                    index === breadcrumbs.length - 1 
                                        ? 'text-foreground cursor-default' 
                                        : 'text-muted-foreground hover:text-secondary cursor-pointer'
                                ]"
                                @click="index < breadcrumbs.length - 1 ? dms.toFolder(folder.id) : null">
                                <h1 class="text-lg font-bold" :class="{
                                    'hover:text-secondary': index < breadcrumbs.length - 1
                                }">{{ folder.name }}</h1>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </template>
                </BreadcrumbList>
            </Breadcrumb>
        </div>
        <!-- Main content container -->
        <div class="flex-1 flex flex-col overflow-hidden px-4">
            <!-- Toolbar section -->
            <div class="flex flex-row justify-between items-center my-4">
                <div class="flex flex-row flex-wrap gap-2 items-center w-full">
                    <UploadMultiple
                        :acceptedFileTypes="['.txt', '.docx', '.pdf']"
                        :parentId="dms.currentFolderId"
                        @uploadStart="isUploading = true"
                        @uploadComplete="isUploading = false"
                        @uploadError="isUploading = false"
                        @uploadProgress="(progress) => uploadProgress = progress"
                        @filesLengthChange="(length) => filesLength = length"
                        @folderUpload="(isFolder) => isFolderUpload = isFolder"
                    />
                    
                    <ActionCard
                        :icon="FolderPlusIcon"
                        label="Nieuwe map"
                        :onClick="handleCreateFolder"
                        :badgeCount="selectedItemsCount"
                    />

                    <ActionCard
                        :icon="FolderPlusIcon"
                        label="Voeg toe aan map"
                        :onClick="handleAddToFolder"
                        :disabled="selectedItemsCount === 0"
                        :badgeCount="selectedItemsCount"
                    />
                    
                    <ActionCard
                        :icon="Trash2Icon"
                        label="Verwijderen"
                        :onClick="() => handleContextMenuDelete(selectedItems)"
                        :disabled="selectedItemsCount === 0"
                        :badgeCount="selectedItemsCount"
                    />
                </div>
            </div>
            
            <!-- Table container -->
            <div class="flex-1 overflow-auto mb-4">
                <div 
                    class="relative h-full"
                    @dragenter.prevent.stop="onDragEnter"
                    @dragleave.prevent.stop="onDragLeave"
                    @dragover.prevent.stop="onDragOver"
                    @drop.prevent.stop="onDrop"
                >
                    <DmsTable
                        :class="{ 'pointer-events-none': isDragging }"
                        :isLoading="isLoading"
                        :filesLength="filesLength"
                        :isUploading="isUploading"
                        :isFolderUpload="isFolderUpload"
                        :uploadProgress="uploadProgress"
                        :items="items"
                        :breadcrumbs="breadcrumbs"
                        @selectionChange="handleSelectionChange"
                        @folderNameChange="handleFolderNameChange"
                        @contextMenuDelete="handleContextMenuDelete"
                        @contextMenuCreateFolder="handleContextMenuCreateFolder"
                        @folderDoubleClick="handleFolderDoubleClick"
                    />
                    <Transition
                        enter-active-class="transition-opacity duration-300"
                        leave-active-class="transition-opacity duration-300"
                        enter-from-class="opacity-0"
                        leave-to-class="opacity-0"
                    >
                        <div
                            v-if="isDragging"
                            class="absolute inset-0 bg-backgroundSecondary/70 border-2 border-dashed border-secondary/40 rounded-lg flex items-center justify-center z-50"
                        >
                            <p class="text-lg font-semibold text-secondary">Drop files here to upload</p>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>

        <!-- Dialog remains unchanged -->
        <Dialog :open="showCreateFolderDialog" @update:open="closeDialog">
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Nieuwe map aanmaken</DialogTitle>
                    <DialogDescription>
                        {{ itemsToCreateFolder.length > 0 
                            ? `Er worden ${itemsToCreateFolder.length} item${itemsToCreateFolder.length > 1 ? 's' : ''} in deze map geplaatst.` 
                            : 'Er wordt een lege map aangemaakt.' }}
                    </DialogDescription>
                </DialogHeader>
                <div class="flex flex-row space-x-2">
                    <Input v-model="newFolderName" placeholder="Mapnaam" @keyup.enter="submitCreateFolder" />
                </div>
                <DialogFooter>
                    <Button @click="closeDialog" variant="outline">Annuleer</Button>
                    <Button @click="submitCreateFolder" @keyup.enter="submitCreateFolder" variant="default">
                        Maak map aan
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>

        <Dialog :open="showAddToFolderDialog" @update:open="closeAddToFolderDialog">
            <DialogContent class="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Voeg toe aan map</DialogTitle>
                    <DialogDescription>
                        Voeg {{ itemsToAddToFolder.length }} item{{ itemsToAddToFolder.length > 1 ? 's' : '' }} toe aan een bestaande map.
                    </DialogDescription>
                </DialogHeader>
                <div class="flex flex-col space-y-2 max-h-[300px] overflow-y-auto">
                    <div
                        v-for="folder in items.filter(item => item.isFolder)"
                        :key="folder.id"
                        class="relative flex items-center space-x-3 rounded-lg border p-3"
                    >
                        <div class="cursor-pointer">
                            <Checkbox 
                                :checked="selectedFolderId === folder.id"
                                @update:checked="selectedFolderId = $event ? folder.id : null"
                            />
                        </div>
                        <div class="flex items-center space-x-3">
                            <FolderIcon class="h-5 w-5 text-muted-foreground" />
                            <span class="text-sm font-medium leading-none">{{ folder.name }}</span>
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <Button @click="closeAddToFolderDialog" variant="outline">Annuleer</Button>
                    <Button @click="submitAddToFolder" variant="default">
                        Verplaats
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>

    </div>
</template>

<style scoped>
.relative {
    position: relative;
}

.pointer-events-none {
    pointer-events: none;
}
</style>

